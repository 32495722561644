//import './common/v5/global';

import React, {useContext, useEffect, useState} from 'react'
import { ThemeProvider } from '@material-ui/core/styles'

import IEReadiness from './common/v5/ie'
// import loadFiles from './common/v5/dynfiles';

import { createRoot } from 'react-dom/client';
// Redux
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import theme from './common/theme'

import store, { history } from './redux/store/configureStore'

import './common/webpack-public-path'
import App from './containers/app'
// css
import '../sass/inc.scss'
import '../sass/app.inc.scss'
import '../sass/app.extra.scss'

import { SET_UP_APP_CONSTANTS } from './redux/constants/constants';
import ErrorBoundary from './erorr-boundary/errorHandler';
import {loadScript} from './scriptload/externalScript'
import { getInitialData, setGlobals, translationURL } from './lib/api';

const ParentApp = () => {
	const [isLoading, setIsLoading] = useState(false);
	let path = process.env.PUBLIC_URL;

        if(window.location.origin !== ""){
            path = window.location.origin;
        }
	useEffect(() => {
		store.dispatch(function getInitialState(dispatch, getState){
			getInitialData()
			.then(res =>{
				console.log('getInitialData====================>', res);
				setGlobals(res);
				dispatch({type: SET_UP_APP_CONSTANTS, payload: res});
				const state = getState();
				console.log('state = ', state);
				if(res.interfaceLanguage !== 'en'){
					let transURL = translationURL.replace("{interfaceLanguage}", res.interfaceLanguage)
					transURL = transURL.replace("{version}", res.version)
					console.log('transURL = ',transURL);
					loadScript(transURL, null, null)
					.then(script => {
						setIsLoading(true);
					})
					.catch((e) => {
						setIsLoading(true); //in case error, but still show UI
						console.error(e);
						window.location.href = path + "/logout";
						
					});
				}else{
					setIsLoading(true);
				}
			}).catch(error =>{
				console.log("Error: ", error)
				window.location.href = path + "/logout";
			})
		});
	}, []);

	return (
		<div>
		{!isLoading ? (
		<div>{I("Loading the application in progress...")}</div>
		) : (<Provider store={store}>
			<ConnectedRouter history={history}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
			</ConnectedRouter>
		</Provider>)}
		</div>
	);
};

IEReadiness.then(() => {
	// loadFiles();
	const container = document.getElementById('container');
	const root = createRoot(container);
	root.render(<ErrorBoundary>
		<ParentApp/>
	</ErrorBoundary>)
})