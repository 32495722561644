import React from 'react';
import { saveError } from '../lib/api';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}
	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		}, async ()=>{
			try {
				const data = this.state;
				await saveError(data);
			} catch(error) {
				console.debug('Error happened: ', error);
			}
		});
	}
	
	render() {
		if (this.state.errorInfo) {
			return (<div>
				<h2>Something went wrong.</h2>
				<div id='errorDiv' className="error-backdrop">
					<div className="main-container error-modal p-3">
						<div className="err-head">Error!
							<div id="closeButton" onclick="closeError()" className="closeModal float-right"><i className="icon-times"></i></div>
						</div>
						<div className="err-body">
							<div className="description">
								<h4>We're sorry, an error occurred.</h4>
								<h4>You can contact our support and provide the reference code below</h4>
								<h4>for further assistance.</h4>
								<h4>RefCode : <span id='errorId'></span></h4>
							</div>
							<div className="error-details">
								<h3 id="toggleMore" onclick="showErrorDetails(true)" className='toggleMore book'>See more </h3>
								<h3 id='moreErrorDetails' className="book hide" style={{textDecoration: "underline"}}>Loading ...</h3>
								<h3 id="toggleLess" onclick="showErrorDetails(false)" className='toggleLess book hide'>See less </h3>
								<details style={{ whiteSpace: 'pre-wrap' }}>
								{this.state.error && this.state.error.toString()}
								<br />
								{this.state.errorInfo.componentStack}
							</details>
							</div>
						</div>
						<div className="err-footer">
							<div onclick="closeError()" className='closeModal btn-blue float-right'>Ok</div>
						</div>
					</div>
				</div>
			</div>
			);
		}
		return this.props.children;
	}
}
export default ErrorBoundary;