// constants.js is common place for v5 js files. Constants should be created
// based its usage and not base on its value. For example, D_BASIC_ERRANDS
// should be used when any code accessing the domain branch state
// D_BASIC_ERRANDS.
import {
 	I,
	cloudFrontURL
} from './config';
//Note(mujibur): Here the cloudFrontURL uses is ok, since its imported from config.js which
//has resolved the name.
import {
	emptyArray as _emptyArray
	, emptyObject as _emptyObject
	, INPUT_EMPTY_WARNING as _INPUT_EMPTY_WARNING
} from '../constants';
import {
	CHATBOTS,
	CLOSE_RECEIPTS,
	FEEDBACKS,
	GREETINGS,
	RECEIVE_RECEIPTS,
	SMTP
} from '../path';
import { TXT_CHATBOTS } from './chatbotConstants';
import { TXT_RECEIPT_GREETINGS } from './receiptGreetingConstants';
import { TXT_SMTP } from './smtpConstants';
import {
	AdminAccountEmail,
	AdminAccountLine,
	AdminAccountInstagram,
	AdminAccountTwitter,
	AdminAccountLinkedIn,
	AdminAccountJira,
	AdminAccountHubSpot,
	AdminAccountMSTeams,
	AdminAccountTrustpilot,
	AdminAccountTelegram,
	AdminAccountWhatsApp,
	AdminAccountTwilio,
	AdminAccountViber,
	AdminAccountSlack,
	AdminAccountYoutube,
	AdminAccountGoogleReview,
	AdminAccountGoogleChat,
	AdminAccountGooglePlay,
	AdminAccountSMPP,
	AdminAccountFacebook
} from '../../redux/constants/endpoints'

//THEMING
export const DARK_MODE_APPEARANCE = [
	{id:0, name: "No"},
	{id:1, name: "Yes"},
	{id:2, name: "Auto"}
]

export const TXT_AREA_AVAILABLE = I("Area {AREA_ID} isn't available to you");

export const TXT_ORG_AVAILABILITY = I("Organization {ORG_ID} isn't available to you");
export const TXT_SYSTEM_NEED_RELOGIN = I("You have been logged out, please proceed to the login page.");

export const I18N_AGGREGATE = I("Aggregate")
	, I18N_NO_AGGREGATE = I("No Aggregate")
	;
const { Errand, Action } = Workflow;

export const HISTORY_POP = "POP";

export const emptyArray = _emptyArray
	, emptyObject = _emptyObject
	;
export const C3ERR_SAME_ID = {sameID: true}
	;
// normal strings
const ADD_STR = 'add', DEL_STR = 'del', CLR_STR = 'clear';

export const ADMIN_CKE_DEF_HEIGHT = 140
	, ADMIN_INSERT_PROTECT_CKE_HEIGHT = 450
	;
export const NO_DATA_STR = I("No Data Available.");

export const UNSELECT = -1;

export  const DEFAULT_ERRAND_LENGTH_LIMIT = 250;

export const UNKNOWN_WORKFLOW_SERVICE_TYPE = 0;

export const DEF_OPT = {};

export const DEF_AREA_DATA = {
	addressbook_addresses: emptyArray,
	agents: emptyArray,
	area_admin_tick_sal: false,
	area_admin_tick_sign: false,
	area_salutation_pref: 0,
	area_signature_pref: 0,
	confirmation: false,
	delete_tags: emptyArray,
	due_date_selected: false,
	externalId: '',
	external_addresses: emptyArray,
	external_system_url: null,
	external_system_url_array: null,
	fbAccounts: null,
	file_archive_images: emptyArray,
	forward_to_areas: emptyArray,
	hasPostpone: false,
	id: 0,
	library: 0,
	lineAccounts: null,
	name: '',
	normal_tags: emptyArray,
	reply_to_address: '',
	reply_to_sms: '',
	salutation_contents: emptyArray,
	salutations: emptyArray,
	serverEmails: null,
	signature_contents: null,
	signatures: emptyArray,
	templates: false,
	template_contents: emptyArray,
	quickReplies: false,
	quickreply_contents: emptyArray,
	twAccounts: null,
	user_salutation_pref: 0,
	user_salutations: emptyArray,
	user_salutations_content: null,
	user_signature_pref: 0,
	user_signatures: emptyArray,
	user_signatures_content: null,
	vkAccounts: null,
	lkAccounts: null,
};

export const DEFAULT_ACCOUNT_TITLE = I('Account');

export const OBJ_ID_NAME = {id: "Id", name: "Name"};

// general common string
export const INVALID_DATA_STR = I("Invalid data")
	, SAVE_AS_EML_STR = I('Save as eml')
	;
// post link format
export const PLF_PREVIEW = "preview"
	, PLF_SAVE_AS_EML = "save-as-eml"
	, PLF_DOWNLOAD = PLF_SAVE_AS_EML
	;
// parse format
export const PF_TIMESTAMP = "X"
	, PF_HISTORY_CREATED = "YYYY-MM-DD HH:mm:ss"
	, PF_DEFAULT = "YYYY/MM/DD HH:mm"
	, PF_DEFAULT_FULL = "YYYY/MM/DD HH:mm:ss"
	;

export const EE_BULB_ON = cloudFrontURL + '/img/icons/ee-on.png',
	EE_BULB_OFF = cloudFrontURL + '/img/icons/ee-off.png',
	EE_BULB_ON_READ = cloudFrontURL + '/img/icons/ee-on-read.png';

// agent status
export const AS_AVAILABLE = 'Available'
	, AS_AWAY = 'Away'
	, AS_BREAK = 'Break'
	;

export const HEADER_USER_STATUS = 'headerUserStatus',
	HEADER_USER_PROFILE = 'headerUserProfile',
	HEADER_USER_NOTIFICATION = 'headerNotification';

// last save start state
export const LSSS_INVALID = 1
	, LSSS_VALID = 2
	;
// set current errand action's state
export const JUST_LOADED_STR = 'just_loaded',
	UNSELECT_STR = 'unselect',
	OPEN_STR = 'open',
	EOPR_ANSWERED = 'answered';

export const OTHER_CONTACTS_FETCH_SIZE = 25,
	OTHER_CONTACTS_FETCH_MIN_SIZE = 2,
	OTHER_CONTACTS_FETCH_ALL_SIZE = 9999;

// MCAM ID
export const FETCH_AREA_DATA_STR = 'FetchAreaData';

// domain branch state
export const D_BASIC_ERRANDS = 'basicErrands'
	, D_AREAS = 'areas'
	, D_CLIENT_AVATAR = 'clientAvatar'
	, D_ERRAND_NOTES = 'errandNotes'
	, D_EXTENDED = 'extended'
	, D_EE_EDIT = 'externalExpertEdit'
	, D_EE_LIST = 'externalExpertList'
	, D_HISTORY = 'history'
	, D_TAGS_INFO = 'tagsInfo'
	, D_FORWARD_AGENTS = 'forwardAgents'
	, IS_HISTORY_ATTACHMENT = 'history-attachment'
	, TEMPLATE_USED_FOR_ERRAND = 0
	, TEMPLATE_USED_FOR_WHATSAPP = 1
	;
export const PC_REJECT = "reject"
	, PC_RESOLVE = "resolve"
	;
export const TMR_FETCH_LIST = 60000
	, TMR_FETCH_LIST_FEATURE = 300000
	, TMR_AGENT_STATUS = 120000
	, TMR_AUTO_SAVE = 90000
	, TMR_ERRAND_COUNTER = 60000
	, TMR_AGENT_NO_TYPING = 2000
	, THROTTLED_TMR = 333
	, TMR_AGENT_IMPORT_STATUS = 1000
	, TMR_FETCH_LAUNCHPAD = 60000
	;
// unknown user photo link
export const UNKNOWN_PHOTO_LINK = cloudFrontURL + '/img/user-icon.png';

export const IMG_LOADING = cloudFrontURL + '/img/loading-attachment.gif';

// default errand tag color
export const DEFAULT_TAG_COLOR = '#8ac8ff';

// acquire errand type
export const ACQ_ERD_OTHER = 0
	, ACQ_ERD_HISTORY = 1
	, ACQ_ERD_SELECTED_TOP = 2
	, ACQ_ERD_SELECTED_BOTTOM = 3
	, ACQ_ERD_MY = 4
	, ACQ_ERD_LINKED = 5
	, SEL_ACT_ACQ_ERD_OTHER = 'select-other'
	, SEL_ACT_ACQ_ERD_HISTORY = 'select-history'
	, SEL_ACT_ACQ_SELECT = 'select-group'
	, SEL_ACT_OTHER_CONTACT = 'select-other-contact'
	, SEL_ACT_MY_ERD = 'select-my-errand'
	, SEL_ACT_LINKED_ERD = 'select-linked-errand'
	;
// acquire errand tab
export const AET_OPEN = "open"
	, AET_HISTORIES = "histories"
	, AET_ACQUIRE = "acquire"
	, AET_OPENTYPE_OWN = "own"
	, AET_OPENTYPE_MYERRANDS = "myErrands"
	, AET_LINKED = "linked"
	;
// internal comment state
export const ICST_UNKNOWN = 0, ICST_NO_DATA = 1, ICST_DATA_FETCHED = 2;

// messsage type
export const MT_ERRAND = 0, MT_NOTE = 1, MT_COLLABORATION = 2;

// button types
export const BTN_CALL = 'call'
	, BTN_CLEAR = 'clear'
	, BTN_CREATE = 'create'
	, BTN_SAVE = 'save'
	, BTN_SEND = 'send'
	, BTN_SEND_ALL = 'send-to-all'
	, BTN_REOPEN = 'reopen'
	, BTN_RESET = 'reset'
	, BTN_RESEND = 'resend'
	, BTN_PREVIEW = 'preview'
	, BTN_SAVE_AS_EML = 'save-as-eml'
	;
export const DEF_ERRAND_BUTTONS_ORDER = [
		BTN_CLEAR
		, BTN_SEND
		, BTN_SEND_ALL
		, BTN_CALL
		, BTN_SAVE
		, BTN_REOPEN
		, BTN_RESET
		, BTN_RESEND
		, BTN_PREVIEW
		, BTN_SAVE_AS_EML
	]
	, DEF_MANUAL_BUTTONS_ORDER = [
		BTN_CLEAR
		, BTN_CREATE
		, BTN_SEND
		, BTN_SAVE
		, BTN_PREVIEW
		, BTN_SAVE_AS_EML
	]
	;

export const BTN_TXT_SAVING = I('Saving...')
, BTN_TXT_SAVE = I('Save');

// errand notes operation
export const NOTEOPR_DEFAULT = 0
	, NOTEOPR_CREATE_NEW = 1
	;
// errand context
export const CTX_MANUAL = 'Manual Errand'
	, CTX_MY = 'My Errands'
	, CTX_NEW = 'New Errands'
	, CTX_REVIEW = 'Review Errands'
	, CTX_SEARCH = 'Search Errands'
	, CTX_SEARCH_ANSWER = 'Search Answer'
	, CTX_NEW_SEARCH = 'Search All Errands'
	, CTX_MY_SEARCH = 'Search MY Errands'
	, CTX_CLOSE_SEARCH = 'Search Close Errands'
	, CTX_POSTPONED = 'Postponed Errands'
	, CTX_VIP = 'VIP Errands'
	, CTX_BULK_SEND = 'Bulk Send Errands'
	;
export const DEFAULT_FOLDER_NAME = CTX_MY;

export const NEW_MANUAL_CTX = 'New Manual Errand';
//export menu title
export const MENU_ERRANDS = 'Errands', MENU_SEARCH = 'Search';

// errand answer state
export const E_A_UNKNOWN = 0,
	E_A_SAVING = 1,
	E_A_SENDING = 2,
	E_A_ANSWERED = 3;

// expert answer state
export const EXPERT_ANS_IDLE = 0, 
	EXPERT_ANS_SAVING = 1,
	EXPERT_ANS_SENDING = 2; 

// errand actions (history/thread part)
export const ACT_CLOSED = 1
	, ACT_ANSWERED = 2
	, ACT_FORWARDED = 3
	, ACT_SAVE = 4
	, ACT_UNKNOWN = 5
	;
// errand date
export const UNKNOWN_DATE = 'unknown date';

//errand advance search

export const ADV_SEARCH_DATE_INTERVAL = 1, ADV_SEARCH_ERRAND_TYPE = 3,ADV_SEARCH_SORT_ORDER = 4;
export const SEARCH_SIDEBAR_RESULT_TXT_LIMIT = 40;

// errand reply type
export const RPLY_ERRAND = 'reply',
	RPLY_QUESTION = 'question',
	RPLY_COMMENT = 'comment',
	RPLY_COLLABORATE = 'collaborate',
	RPLY_EXT_FWD = 'forwardExt',
	RPLY_GRP_CHAT_COL = 'group chat collaborate',
	RPLY_CHAT_SUMMARY = 'chat summary',
	RPLY_MANUAL = 'manual',
	RPLY_MANUAL_COMMENT = 'manualComment',
	RPLY_INTERNAL_MESSAGE = 'internalMsg',
	RPLY_MANUAL_IM = 'manual IM',
	RPLY_IM = 'reply IM';

// errand reply option tabs
export const RPLY_TAB_RECIPIENTS = 'recipientsTab',
	RPLY_TAB_ASSIST = 'assistTab',
	RPLY_TAB_ATTACHMENT = 'attachmentsTab',
	RPLY_TAB_NO_SELECT = 'none';

//replyBox tags
export const SHOW_MORE_TAGS_WIDTH = 90;

//chat errand reply tabs
export const CHAT_REPLY_TABS_ATTACHMENT = 'chatAttachmentsTab',
	CHAT_RPLY_TAB_COLLABORATION = 'chatCollaborationTab'

// chat errand input tag width (px)
export const CHAT_REPLY_INPUT_TAGS_WIDTH = 60;

// TODO: get email template int from server
export const EMAIL_TYPE_FROM_REPLY = {
	[RPLY_ERRAND]: 1 // workflow.EmailTemplate_ANSWER_ERRAND
	, [RPLY_EXT_FWD]: 8 // workflow.EmailTemplate_EXTERNAL_FORWARD
};

// popup box type
export const POPBOX_ALERT = 0,
	POPBOX_CONFIRM = 1,
	POPBOX_CUSTOM = 2,
	POPBOX_WAIT = 3,
	POPBOX_AUTO_LOGOUT = 4;

// countdown in seconds before auto logout
export const AUTO_LOGOUT_COUNTDOWN_SECONDS = 60;

export const ICON_V5_EMAIL = 'icon-v5-email'
export const ICON_REPLY = 'icon-reply'

//Note(mujibur): its a hack! DONT use it
//be reminded: this kind of variables never been re-initialized
//as it comes from server via API call. Currently its working as
//global variable that updated by API.
export const DEFAULT_ERRAND_TYPE_ICONS = {
	[Errand.SERVICE_EMAIL]: ICON_V5_EMAIL
	, [Errand.SERVICE_MANUAL]: ICON_V5_EMAIL
	, [Errand.SERVICE_FACEBOOK]: "icon-v5-facebook"
	, [Errand.SERVICE_MANUAL_FACEBOOK]: "icon-v5-facebook"
	, [Errand.SERVICE_TWITTER]: "icon-x-twitter"
	, [Errand.SERVICE_MANUAL_TWITTER]: "icon-x-twitter"
	, [Errand.SERVICE_LINKEDIN]: "icon-v5-linkedin"
	, [Errand.SERVICE_MANUAL_LINKEDIN]: "icon-v5-linkedin"
	, [Errand.SERVICE_INSTAGRAM]: "icon-v5-instagram"
	, [Errand.SERVICE_MANUAL_INSTAGRAM]: "icon-v5-instagram"
	, [Errand.SERVICE_LINE]: "icon-v5-line"
	, [Errand.SERVICE_MANUAL_LINE]: "icon-v5-line"
	, [Errand.SERVICE_VKONTAKTE]: "icon-v5-vk"
	, [Errand.SERVICE_MANUAL_VKONTAKTE]: "icon-v5-vk"
	, [Errand.SERVICE_CHAT]: "icon-v5-chat"
	, [Errand.SERVICE_SMS]: "icon-v5-sms"
	, [Errand.SERVICE_MANUAL_SMS]: "icon-v5-sms"
	, [Errand.SERVICE_VOICE]: "icon-v5-phone"
	, [Errand.SERVICE_MANUAL_VOICE]: "icon-v5-phone"
	, [Errand.SERVICE_FORM]: "icon-webform"
	, [Errand.SERVICE_YOUTUBE]: "icon-v5-youtube"
	, [Errand.SERVICE_MANUAL_YOUTUBE]: "icon-v5-youtube"
	, [Errand.SERVICE_WHATSAPP]: "icon-v5-whatsapp"
	, [Errand.SERVICE_GOOGLEPLAY]: "icon-v5-play-store"
	, [Errand.SERVICE_CHATBOT]: "icon-chatbot"
	, [Errand.SERVICE_TELEGRAM]: "icon-telegram"
	, [Errand.SERVICE_TRUSTPILOT]: "icon-trustpilot"
	, [Errand.SERVICE_VIBER]: "icon-viber"
	, [Errand.SERVICE_GOOGLEREVIEW]: "fas fa-business-time"
	, [Errand.SERVICE_SLACK]: "icon-v5-slack"
	, [Errand.SERVICE_MICROSOFT_TEAMS]: "icon-ms-teams"
	//Note: For App Store, use icon-v5-app-store
}

export const RC_EMAIL = 'email'
	, RC_CHAT = 'chat'
	, RC_FACEBOOK = 'facebook'
	, RC_LINE = 'line'
	, RC_LINKEDIN = 'linkedin'
	, RC_FB_MESSENGER = 'fbmessenger'
	, RC_TW_MESSENGER = 'twdiectmessage'
	, RC_SMS = 'sms'
	, RC_TWITTER = 'twitter'
	, RC_VK = 'vkontakte'
	, RC_INSTAGRAM = 'instagram'
	, RC_GROUP = 'group'
	, RC_IM = 'internal message'
	, RC_VOICE = 'voice'
	, RC_YOUTUBE = 'youtube'
	, RC_GOOGLEPLAY = 'googleplay'
	, RC_WHATSAPP = 'whatsapp'
	, RC_FORM = 'form'
	, RC_CHATBOT = 'chatbot'
	, RC_AVENTA = 'aventa'
	, RC_SLACK = 'slack'
	, RC_TELEGRAM = 'telegram'
	, RC_TRUSTPILOT = 'trustpilot'
	, RC_VIBER = 'viber'
	, RC_MSTEAMS = 'Microsoft Teams'
	, RC_JIRA = "JIRA"
	, RC_GOOGLEREVIEW = "googlereview"
	, RC_GOOGLECHAT = 'Google chat'
	, RC_BACK_TO_ORIGIN = 'origin'
	;
// alert confirm popup box operation
export const ALRT_CFRM_OPR_NORMAL = 'normal', // just yes or no confirmation.
	ALRT_CFRM_OPR_OPTIONAL = 'optional_confirm', // seperate data for yes.
	ALRT_CFRM_OPR_DEL_NOTE = 'delete_note', // specific op for delete note.
	ALRT_CFRM_OPR_EDIT_NOTE = 'edit_note',
	ALRT_CFRM_OPR_SEND_ANS = 'send_answer',
	ALRT_CFRM_OPR_BLOCK_IP = 'block_ip';

export const MEDIA_ACTION = {
	FB_EMOTION_HISTORY:	'fb_emotion_history',
	FB_PROFILE:		'fb_profile',
	FB_HISTORY:		'fb_history',
	FB_LIKE:		'fb_like',
	FB_UNLIKE:		'fb_unlike',
	FB_HIDE:		'fb_hide',
	FB_UNHIDE:		'fb_unhide',
	FB_DELETEQ:		'fb_deleteq',
	FB_RATING:		'fb_rating',
	FB_UPDATE_ANS:	'fb_update',
	FB_DELETE_ANS:	'fb_deleteAns',
	FB_SENDPM:		'fb_sendpm',
	TWT_PROFILE:	'twt_profile',
	TWT_HISTORY:	'twt_history',
	TWT_RETWEET:	'twt_retweet',
	TWT_UNRETWEET:	'twt_unretweet',
	TWT_LIKE:		'twt_like',
	TWT_UNLIKE:		'twt_unlike',
	TWT_DELETEQ:	'twt_deleteq',
	TWT_SENDPM:		'twt_sendpm',
	TWT_DELETE_ANS:	'twt_deleteAns',
	VK_PROFILE:		'vk_profile',
	VK_HISTORY:		'vk_history',
	VK_LIKE:		'vk_like',
	VK_UNLIKE:		'vk_unlike',
	VK_DELETEQ:		'vk_deleteq',
	VK_SENDPM:		'vk_sendpm',
	VK_DELETE_ANS:	'vk_deleteq',
	VK_UPDATE_ANS:	'vk_update',
	INS_HIDE:		'ins_hide',
	INS_UNHIDE:		'ins_unhide',
	INS_DELETEQ:	'ins_deleteq',
}

export const CHANNEL_IDENTIFIER = {
	facebook: {
		capName: "Facebook"
		, prefix: "facebook-"
	}
	, facebook_manual: {
		capName: "Facebook (manual)"
		, prefix: "facebook-"
	}
	, twitter: {
		capName: "Twitter"
		, prefix: "twitter-"
	}
	, twitter_manual: {
		capName: "Twitter (manual)"
		, prefix: "twitter-"
	}
	, line: {
		capName: "LINE"
		, prefix: "centionline-"
	}
	, line_manual: {
		capName: "LINE (manual)"
		, prefix: "centionline-"
	}
	, linkedIn: {
		capName: "LinkedIn"
		, prefix: "linkedin-"
	}
	, linkedIn_manual: {
		capName: "LinkedIn (manual)"
		, prefix: "linkedin-"
	}
	, instagram: {
		capName: "Instagram"
		, prefix: "instagram-"
	}
	, whatsapp: {
		capName: "WhatsApp"
		, prefix: "wa-"
	}
	, whatsapp_manual: {
		capName: "WhatsApp (manual)"
		, prefix: "wa-"
	}
	, vkontakte: {
		capName: "VKontakte"
		, prefix: "vkontakte-"
	}
	, vkontakte_manual: {
		capName: "VKontakte (manual)"
		, prefix: "vkontakte-"
	}
	, googleReview: {
		capName: "Google Review"
		, prefix: "googlereview-"
	}
	, trustpilot: {
		capName: "Trustpilot"
		, prefix: "trustpilot-"
	}
	, telegram: {
		capName: "Telegram"
		, prefix: "tg-"
	}
	, viber: {
		capName: "Viber"
		, prefix: "vb-"
	}
	, youtube: {
		capName: "Youtube"
		, prefix: "youtube-"
	}
	, youtube_manual: {
		capName: "Youtube (manual)"
		, prefix: "youtube-"
	}
}

const MEDIA_URL = {
	FB_URL: {
		name: RC_FACEBOOK
		, capName: "Facebook"
		, url: "https://www.facebook.com/"
	}
	, TWT_URL: {
		name: RC_TWITTER
		, capName: "Twitter"
		, url: "https://www.twitter.com/"
	}
	, LNK_URL: {
		name: RC_LINKEDIN
		, capName: "Linedin"
		, url: "https://www.linkedin.com/"
	}
	, VK_URL: {
		name: RC_VK
		, capName: "VKontakte"
		, url: "https://www.vk.com/"
	}
	, INS_URL: {
		name: RC_INSTAGRAM
		, capName: "Instagram"
		, url: "https://www.instagram.com/"
	}
	, YT_URL: {
		name: RC_YOUTUBE
		, capName: "Youtube"
		, url: "https://www.youtube.com/"
	}
	, GP_URL: {
		name: RC_GOOGLEPLAY
		, capName: "Google Play"
		, url: "https://www.play.google.com/"
	}
	, GR_URL: {
		name: RC_GOOGLEREVIEW
		, capName: "Google Review"
		, url: "https://business.google.com/"
	}
	, WA_URL: {
		name: RC_WHATSAPP
		, capName: "Whatsapp"
		, url: "https://www.web.whatsapp.com/"
	}
	, TG_URL: {
		name: RC_TELEGRAM
		, capName: "Telegram"
		, url: "https://www.telegram.org/"
	}
	, TP_URL: {
		name: RC_TRUSTPILOT
		, capName: "Trustpilot"
		, url: "https://www.trustpilot.com/"
	}
	, TG_URL: {
		name: RC_VIBER
		, capName: "Viber"
		, url: "https://www.viber.com/"
	}
};

const ICON_TRASH = "icon-trash";
const ICON_LIKE = "icon-thumbs-up"
const ICON_UNLIKE = "icon-thumbs-down"

//merge 2 objects
export const MEDIA_ACTION_URL = {...MEDIA_ACTION, ...MEDIA_URL};

const facebookActions = {
	fb_emotion_history: 	{name: I('Emoticons'), icon: 'icon-clock', active: false},
	fb_history: 	{name: I('History'), icon: 'icon-clock', active: false},
	fb_like: 		{name: I('Like'), icon: ICON_LIKE, active: false},
	fb_unlike: 		{name: I('Unlike'), icon: 'icon-thumbs-up-fill', active: false},
	fb_hide: 		{name: I('Hide'), icon: 'icon-eye-blocked', active: false},
	fb_unhide: 		{name: I('Unhide'), icon: 'icon-eye', active: false},
	fb_rating: 		{name: I('Rating'), icon: 'icon-star-full', active: false},
	fb_update: 		{name: I('Update answer'), icon: 'icon-pencil', active: false},
	fb_sendpm: 		{name: I('Send PM'), icon: 'icon-v5-messenger', active: false},
	fb_deleteq: 	{name: I('Delete question'), icon: ICON_TRASH, active: false},
	fb_deleteAns: 	{name: I('Delete answer'), icon: ICON_TRASH, active: false},
};

const twitterActions = {
	twt_profile: 	{name: I('Profile'), icon: 'icon-user-circle', active: true},
	twt_history: 	{name: I('History'), icon: 'icon-clock', active: false},
	twt_like: 		{name: I('Like'), icon: 'icon-heart', active: false},
	twt_unlike: 	{name: I('Unlike'), icon: 'icon-heart-filled', active: false},
	twt_retweet: 	{name: I('Retweet'), icon: 'icon-retweet', active: false},
	twt_unretweet: 	{name: I('Undo Retweet'), icon: 'icon-retweet', active: false},
	twt_sendpm: 	{name: I('Direct message'), icon: 'icon-v5-twitter-direct-message', active: false},
	twt_deleteq: 	{name: I('Delete question'), icon: ICON_TRASH, active: false},
	twt_deleteAns: 	{name: I('Delete answer'), icon: ICON_TRASH, active: false},
};

const vkActions = {
	vk_profile: 	{name: I('Profile'), icon: 'icon-user-circle', active: true},
	vk_history: 	{name: I('History'), icon: 'icon-clock', active: false},
	vk_like: 		{name: I('Like'), icon: ICON_LIKE, active: false},
	vk_unlike: 		{name: I('Unlike'), icon: ICON_UNLIKE, active: false},
	vk_update: 		{name: I('Update answer'), icon: 'icon-pencil', active: false},
	vk_sendpm: 		{name: I('Send PM'), icon: 'icon-v5-vk-messenger', active: false},
	vk_deleteq: 	{name: I('Delete question'), icon: ICON_TRASH, active: false},
	vk_deleteAns: 	{name: I('Delete answer'), icon: ICON_TRASH, active: false},
};

const insActions = {
	ins_hide: 		{name: I('Hide'), icon: 'icon-eye-blocked', active: false},
	ins_unhide: 	{name: I('Unhide'), icon: 'icon-eye', active: false},
	ins_deleteq: 	{name: I('Delete question'), icon: ICON_TRASH, active: false}
};

const waActions = {
	wa_like: 		{name: I('Like'), icon: 'icon-fb-love', active: false},
	wa_unlike: 	{name: I('Unlike'), icon: 'icon-fb-unlove', active: false}
};


const manualFacebook = {
		choose: I('Choose Facebook account')
		, account: I('Facebook account')
		, type: Errand.SERVICE_MANUAL_FACEBOOK
	}
	, manualLINE = {
		choose: I('Choose LINE account')
		, account: I('LINE account')
		, type: Errand.SERVICE_MANUAL_LINE
	}
	, manualVK = {
		choose: I('Choose VKontakte account')
		, account: I('VKontakte account')
		, type: Errand.SERVICE_MANUAL_VKONTAKTE
	}
	, manualVoice = {
		choose: I('Choose phone number')
		, account: I('Caller ID')
		, type: Errand.SERVICE_VOICE
	}
	, manualTwitter = {
		choose: I('Choose Twitter account')
		, account: I('Twitter account')
		, type: Errand.SERVICE_MANUAL_TWITTER
	}
	, manualWhatsapp = {
		choose: I('Choose Whatsapp account')
		, account: I('Whatsapp account')
		, type: Errand.SERVICE_MANUAL_WHATSAPP
	}
	, manualLinkedin = {
		choose: I('Choose LinkedIn account')
		, account: I('LinkedIn account')
		, type: Errand.SERVICE_MANUAL_LINKEDIN
	}
	, defaultSimpleToolbar = {simpleToolbar: true}
	, defaultToolbar = {simpleToolbar: false}
	;
export const DEFAULT_SIMPLE_TOOLBAR = defaultSimpleToolbar;

export const DEFAULT_CKEDITOR_HEIGHT = 100, DEFAULT_CKEDITOR_HEIGHT_CHAT = 30;

export const CHAT_CKEDITOR_ID = 'chatEditor'
export const CHAT_CKE_PLACEHOLDER_TEXT = I("Write a message...");

export const CKEDITOR_DARK_BG = '#3F3F3F';
export const CKEDITOR_DARK_TXT_COLOR = '#B9BBBD';

export const CKEDITOR_DARK_BG_COLLAB = '#0B1E26';
export const CKEDITOR_DARK_TXT_COLLAB = '#FFFFFF';

export const CKEDITOR_DARK_BG_COMMENT = '#302A0B';
export const CKEDITOR_DARK_TXT_COMMENT = '#FEF8F0';

export const CKEDITOR_BG_COLLAB = '#EAF8FE';
export const CKEDITOR_BG_COMMENT = '#FFF9EC';

export const ERRAND_PAGINATION_SIZE_OPT = [10, 15, 17, 25, 35, 50];

export const CHAT_SOURCE_ONLINE = 1,
	CHAT_SOURCE_ENABLED = 2,
	CHAT_SOURCE_OFFLINE = 3

export const CH_AREA_LINK = {
	[RC_FACEBOOK]: 'fbAccounts',
	[RC_LINE]: 'lineAccounts',
	[RC_VK]: 'vkAccounts',
	[RC_TWITTER]: 'twAccounts',
	[RC_YOUTUBE]: 'ytAccounts',
	[RC_WHATSAPP]: 'waAccounts',
	[RC_GOOGLEPLAY]: 'gpAccounts',
	[RC_TELEGRAM]: 'tgAccounts',
	[RC_TRUSTPILOT]: 'tpAccounts',
	[RC_VIBER]: 'vbAccounts',
	[RC_GOOGLEREVIEW]: "grAccounts",
	[RC_LINKEDIN]: "lkAccounts",
};

export const OPT_FIELDS_ID_VALUE = {id: "id", value: "value"}
	, OPT_CHANNELS = {
		[RC_EMAIL]: {name: I('E-mail'), toolbar: defaultToolbar, icon: "icon-mail"}
		, [RC_CHAT]: {name: I('Chat'), toolbar: {simpleChatToobar: true}, icon: "icon-chat"}
		, [RC_FACEBOOK]: {
			name: I('Facebook')
			, toolbar: defaultSimpleToolbar
			, manual: manualFacebook
			, actions: facebookActions
			, icon: "icon-v5-facebook"
		}
		, [RC_LINE]: {
			name: I('LINE')
			, toolbar: defaultSimpleToolbar
			, manual: manualLINE
			, icon: "icon-v5-line"
		}
		, [RC_LINKEDIN]: {
			name: I('LinkedIn')
			, manual: manualLinkedin
			, toolbar: defaultSimpleToolbar
			, icon: "icon-v5-linkedin"
		}
		, [RC_YOUTUBE]: {name: I('Youtube'), toolbar: defaultSimpleToolbar, icon: "icon-youtube"}
		, [RC_GOOGLEPLAY]: {name: I('Google Play'), toolbar: defaultSimpleToolbar, icon: "icon-v5-play-store"}
		, [RC_GOOGLEREVIEW]: {name: I('Google Review'), toolbar: defaultSimpleToolbar, icon: "icon-star"}
		, [RC_FB_MESSENGER]: {name: I('Messenger'), icon: "icon-v5-messenger"}
		, [RC_SMS]: {name: I('SMS'), toolbar: defaultSimpleToolbar, icon: "icon-chat"}
		, [RC_VOICE]: {
			name: I('VOICE')
			, toolbar: defaultSimpleToolbar
			, manual: manualVoice
			, icon: "icon-call"
		}
		, [RC_TWITTER]: {
			name: I('Twitter')
			, toolbar: defaultSimpleToolbar
			, manual: manualTwitter
			, actions: twitterActions
			, icon: "icon-x-twitter"
		}
		, [RC_VK]: {
			name: I('VKontakte')
			, toolbar: defaultSimpleToolbar
			, manual: manualVK
			, actions: vkActions
			, icon: "icon-v5-vk-messenger"
		}
		, [RC_INSTAGRAM]: {
			name: I('Instagram')
			, toolbar: defaultSimpleToolbar
			, actions: insActions
			, icon: "icon-v5-instagram"
		}
		, [RC_WHATSAPP]: {
			name: I('Whatsapp')
			, toolbar: defaultSimpleToolbar
			, manual: manualWhatsapp
			, icon: "icon-v5-whatsapp"
		}
		, [RC_FORM]: {
			name: I('Form')
			, toolbar: defaultSimpleToolbar
			, icon: "icon-report-executive"
		}
		, [RC_CHATBOT]: {
			name: I('Chat bot')
			, toolbar: defaultSimpleToolbar
		}
		, [RC_SLACK]: {name: I('Slack'), toolbar: defaultToolbar, icon: "icon-v5-slack"}
		, [RC_MSTEAMS]: {name: I('Microsoft Teams'), toolbar: defaultToolbar, icon: "icon-ms-teams"}
		, [RC_GOOGLECHAT]: {name: I('Google Chat'), toolbar: defaultToolbar, icon: "icon-google-chat"}
		, [RC_JIRA]: {name: I('JIRA'), toolbar: defaultToolbar, icon: "icon-jira"}
		, [RC_TELEGRAM]: {
			name: I('Telegram')
			, toolbar: defaultSimpleToolbar
			, icon: "icon-telegram"
		}
		, [RC_TRUSTPILOT]: {
			name: I('Trustpilot')
			, toolbar: defaultSimpleToolbar
			, icon: "icon-trustpilot"
		}
		, [RC_VIBER]: {
			name: I('Viber')
			, toolbar: defaultSimpleToolbar
			, icon: "icon-viber"
		}
		, [RC_BACK_TO_ORIGIN]: {
			name: I('Back to origin')
			, toolbar: defaultSimpleToolbar
			, icon: "icon-include-fullhistory"
		}
	}
	;

//channel names (1st letter capital)
const CH_EMAIL = 'Email'
, CH_CHAT = 'Chat'
, CH_FACEBOOK = 'Facebook'
, CH_TWITTER = 'Twitter'
, CH_LINKEDIN = 'LinkedIn'
, CH_LINKEDIN_MANUAL = 'LinkedIn (manual)'
, CH_SMS = 'SMS'
, CH_LINE = 'LINE'
, CH_VKONTAKTE = 'Vkontakte'
, CH_VOICE = 'Voice'
, CH_INSTAGRAM = 'Instagram'
, CH_FORM = 'Form'
, CH_YOUTUBE = 'Youtube'
, CH_WHATSAPP = 'WhatsApp'
, CH_CHAT_BOT = 'Chat bot'
, CH_SLACK = 'Slack'
, CH_TELEGRAM = 'Telegram'
, CH_VIBER = 'Viber'
, CH_TRUSTPILOT = 'Trustpilot'
, CH_JIRA = 'JIRA'
, CH_MSTEAMS = 'Microsoft Teams'
, CH_GOOGLEREVIEW = 'Google Review'
, CH_GOOGLECHAT = 'Google chat'
, CH_SIP_VOICE = 'SIP Voice'
, CH_SIP_VIDEO = 'SIP Video'

//match icon by name
export const CHANNEL_ICONS_MAP = {
	[CH_EMAIL]:'icon-v5-email'
	, [CH_CHAT]:'icon-v5-chat'
	, [CH_FACEBOOK]:'icon-v5-facebook'
	, [CH_TWITTER]:'icon-x-twitter'
	, [CH_LINKEDIN]:'icon-v5-linkedin'
	, [CH_LINKEDIN_MANUAL]:'icon-v5-linkedin'
	, [CH_SMS]:'icon-v5-sms'
	, [CH_LINE]:'icon-v5-line'
	, [CH_VKONTAKTE]:'icon-v5-vk'
	, [CH_VOICE]:'icon-v5-phone'
	, [CH_INSTAGRAM]:'icon-v5-instagram'
	, [CH_FORM]:'icon-webform'
	, [CH_YOUTUBE]:'icon-youtube'
	, [CH_WHATSAPP]:'icon-v5-whatsapp'
	, [CH_CHAT_BOT]:'icon-chatbot'
	, [CH_SLACK]:'icon-v5-slack'
	, [CH_TELEGRAM]:'icon-telegram'
	, [CH_VIBER]:'icon-viber'
	, [CH_TRUSTPILOT]:'icon-trustpilot'
	, [CH_JIRA]:'icon-jira'
	, [CH_MSTEAMS]:'icon-ms-teams'
	, [CH_GOOGLEREVIEW]:'icon-star'
	, [CH_GOOGLECHAT]:'icon-google-chat'
	, [CH_SIP_VOICE]:'icon-v5-phone'
	, [CH_SIP_VIDEO]:'icon-video-call'
};

export const ICON_CUSTOM_CHANNEL = 'icon-custom-channel'

export const ME_CREATE = 1, ME_START = 2, ME_NOTE = 4;

export const USED_FOR_WHATSAPP_TEMPLATE = 1, USED_FOR_NORMAL_TEMPLATE = 0;

export const ME_CREATE_AS_NEW = 0 // index base on OPT_ME_CREATE_AS array indexes
	, ME_CREATE_AS_MY = 1
	, ME_CREATE_AS_CLOSED = 2
	;
const OPT_ME_CREATE_AS_NEW = {
		id: ME_CREATE_AS_NEW
		, name: I('as new errand')
		, value: 'as new errand'
	}
	, OPT_ME_CREATE_AS_MY = {
		id: ME_CREATE_AS_MY
		, name: I('in My errands')
		, value: 'in My errands'
	}
	, OPT_ME_CREATE_AS_CLOSED = {
		id: ME_CREATE_AS_CLOSED
		, name: I('as closed errand')
		, value: 'as closed errand'
	}
	;
export const OPT_ME_CREATE_AS = [
		OPT_ME_CREATE_AS_NEW
		, OPT_ME_CREATE_AS_MY
		, OPT_ME_CREATE_AS_CLOSED
	]
	, OPT_ME_CALL_CREATE_AS = [
		OPT_ME_CREATE_AS_MY
		, OPT_ME_CREATE_AS_CLOSED
	]
	;
export const ME_CREATE_ALLOWED_CHANNELS = {
	[RC_EMAIL]: true,
	[RC_SMS]: true,
	[RC_VOICE]: true,
	[RC_LINE]: true,
	[RC_WHATSAPP]: true
};

export const ME_ST_IDLE = 1,
	ME_ST_BUSY = 2,
	ME_ST_CREATED = 3,
	ME_ST_SAVE = 4;

// active type
export const AT_INACTIVE = 1
	, AT_PARTIAL = 2
	, AT_ACTIVE = 3
	;
export const TAG_ADD = ADD_STR,
	TAG_DELETE = DEL_STR
	, TAG_CLEAR = CLR_STR
	;

export const NEW_MANUAL_ERRAND_ID = 0;

export const INPUTS_OPEN_ERRAND = 'inputs',
	INPUTS_MANUAL_ERRAND = 'manualInputs',
	INPUTS_MANUAL_CALL = 'manualCallInputs',
	INPUTS_COLLABORATION = 'collaborationInputs';

export const RECIPIENT_TO = 'to',
	RECIPIENT_GROUP = 'group',
	RECIPIENT_CC = 'cc',
	RECIPIENT_BCC = 'bcc',
	RECIPIENT_FORWARD = 'forward',
	RECIPIENT_INTERNAL_COLLABORATE = 'internal-collaboration';

export const WFP_MANUAL_ERRAND = 1,
	WFP_SALUTATION = 2,
	WFP_SIGNATURE = 3,
	WFP_TEMPLATE = 4,
	WFP_OLD_CHAT = 5,
	WFP_MANUAL_CALL = 6,
	WFP_BULK_SEND_ERRAND = 7;

export const UI_SHOW_MANUAL_ERRAND = 'showManualErrand'
	, UI_SHOW_MANUAL_CALL = 'showManualCall'
	, UI_SHOW_OLD_CHAT = 'showOldChat'
	, UI_SHOW_BULK_SEND_POPUP = 'bulkSendPopup'
	;
export const MP_NONE = 1 // manual popup
	, MP_ERRAND = 2
	, MP_CALL = 3
	, MP_BASIC_CALL = 4
	, MP_MINIMIZE = 5
	, MP_MAXIMIZE = 6
	;
export const MY_ERRANDS = CTX_MY
	, NEW_ERRANDS = CTX_NEW
	, SEARCH_ERRANDS = CTX_SEARCH
	, STATISTICS_PAGE = "Statistics"
	, SEARCH_NEW_ERRANDS = CTX_NEW_SEARCH
	, SEARCH_MY_ERRANDS = CTX_MY_SEARCH
	, SEARCH_CLOSE_ERRANDS = CTX_CLOSE_SEARCH
	, VIP_ERRANDS = I("VIP errands")
	, REVIEW_ERRANDS = CTX_REVIEW
	, POSTPONED_ERRANDS = CTX_POSTPONED
	;
export const PREF_LIST_VIEW = "ListView",
	PREF_CONVERSATION_VIEW = "Conversation";

export const ICON_LIST_VIEW = "icon-list-view",
	ICON_CONVERSATION_VIEW = "icon-card-view";

export const DEFAULT_NO_DUE_DATE = "1970/01/01";

export const FILE_EXCEED_MESSAGE = I("Email delivery failed due to exceeded size limit.");

const NEW_STR = 'new';

export const ICCB_NEW = NEW_STR;

export const ECB_INC_QUESTION = 'include-q',
	ECB_LOCK2ME = 'lock2me',
	ECB_PARTIAL_ANSWER = 'partial',
	ECB_SUGGEST_TO_LIBRARY = 'suggest-library',
	ECB_FWD_ALL_HISTORIES = 'forward-all-histories',
	ECB_INC_HISTORIES = 'include-histories',
	ECB_INC_COLLAB_HISTORY = 'include-collab-history',
	ECB_ALL_CURR_Q_AT = 'all-curr-q-attachments',
	INC_ALL_HISTORY_ATTACHMNET='include-history-attachment';

export const COL_COMPLETE_ERRAND_HISTORIES = 'complete-histories'
	, COL_NEW_THREAD = NEW_STR
	, COL_SAVE_RECIPIENT = 'save-recipient'
	, INC_ALL_HISTORIES_STR = I('Include full history')
	, ALL_Q_AT_STR = I('All current question attachments ({COUNT})')
	, FULL_HISTORY_STR = I('Full history')
	, FULL_COLLAB_HISTORY_STR = I('Include Collaboration history')
	;
const INC_Q_STR = I('Include Question'),
	PARTIAL_ANS_STR = I('Partial answer'),
	SUGGEST2LIB_STR = I('Suggest to Knowledge base'),
	NEW_IC_STR = I('New internal comment');

export const DEF_REPLY_CHECKBOXES_STATE = {
		[ECB_INC_QUESTION]: false,
		[ECB_PARTIAL_ANSWER]: false,
		[ECB_SUGGEST_TO_LIBRARY]: false,
		[ECB_INC_COLLAB_HISTORY]: false,
		[ECB_INC_HISTORIES]: false,
		[INC_ALL_HISTORY_ATTACHMNET]: features["include-all-history-attachment-selected"]?features["include-all-history-attachment-selected"]: false
	}, DEF_COLLABORATE_CHECKBOXES_STATE = {
		[COL_SAVE_RECIPIENT]: undefined, // controlled by other
		[COL_COMPLETE_ERRAND_HISTORIES]: undefined, // controlled by other
		[COL_NEW_THREAD]: undefined // this is controlled by other states
	}, RPLY_CHECKBOX_OPTS = {
		[RPLY_COMMENT]: {
			[ICCB_NEW]: {label: NEW_IC_STR}
		},
		[RPLY_ERRAND]: {
			[ECB_INC_QUESTION]: {
				label: INC_Q_STR
			},
			[ECB_PARTIAL_ANSWER]: {
				label: PARTIAL_ANS_STR
			},
			[ECB_SUGGEST_TO_LIBRARY]: {
				label: SUGGEST2LIB_STR
			}
		},
		[RPLY_EXT_FWD]: {
			[ECB_PARTIAL_ANSWER]: {
				label: PARTIAL_ANS_STR
			},
			[ECB_SUGGEST_TO_LIBRARY]: {
				label: SUGGEST2LIB_STR
			}
		},
		[RPLY_COLLABORATE]: {
			[COL_SAVE_RECIPIENT]: {
				label: I('Save recipient')
			},
			[COL_NEW_THREAD]: {
				label: I('New thread')
			}
		}
	}, replyForwardCheckboxesOrder = [
		ECB_INC_QUESTION,
		ECB_PARTIAL_ANSWER,
		ECB_SUGGEST_TO_LIBRARY,
		ECB_INC_HISTORIES,
		ECB_FWD_ALL_HISTORIES,
		ECB_ALL_CURR_Q_AT
	], RPLY_CHECKBOXES_ORDER = {
		[RPLY_COMMENT]: [ICCB_NEW],
		[RPLY_ERRAND]: replyForwardCheckboxesOrder,
		[RPLY_EXT_FWD]: replyForwardCheckboxesOrder,
		[RPLY_COLLABORATE]: [
			COL_SAVE_RECIPIENT,
			// COL_COMPLETE_ERRAND_HISTORIES,
			COL_NEW_THREAD,
			ECB_FWD_ALL_HISTORIES,
			ECB_INC_COLLAB_HISTORY
		]
	};

export const DEF_ALL_MANUAL_CHECKBOXES_STATE = {
		[ECB_LOCK2ME]: false,
		[ECB_SUGGEST_TO_LIBRARY]: false
	}, MANUAL_CHECKBOX_OPTS = {
		[ME_START]: {
			[ECB_LOCK2ME]: {label: I('Lock to me')},
			[ECB_SUGGEST_TO_LIBRARY]: {label: SUGGEST2LIB_STR}
		}
	}, MANUAL_CHECKBOXES_ORDER = {
		[ME_START]: [
			ECB_LOCK2ME,
			ECB_SUGGEST_TO_LIBRARY
		]
	};

export const EXT_EXPERT_EDIT_ATTACHMENT_TYPE = {
	incomingAttachments: {title: I('Incoming attachments: ')},
	outgoingAttachments: {title: I('Outgoing attachments: ')},
	oldAttachments: {title: I('Old attachments: ')}
};

export const TITLE_ATTACHED_FILES = I('Total'),
	TITLE_COLLABORATE_FILES = I('Collaboration files');

export const NOT_MINE_INVALID = 1,
	NOT_MINE_ERROR = 2,
	NOT_MINE_OTHER_AGENT = 3;

export const DEL_ANSWER_ATTACHMENT = 0, DEL_NOTES_ATTACHMENT = 2, DEL_CHAT_ATTACHMENT = 3;

export const ROLE_AGENT = 1,
	ROLE_EXPERT = 2;
export const MESSAGE_INVALID_EMAIL_ID = 1, INPUT_EMPTY_WARNING_ID = 2;
export const MESSAGE_INVALID_EMAIL = I("* You must provide a valid email address."),
MESSAGE_INVALID_PHONE_NO = I("Please insert valid phone number"),
MESSAGE_INVALID_PASSWORD_FORMAT = I("* Password does not meet minimum criteria"),
MESSAGE_2FA_TOKEN = I("* You must provide a valid Token for 2FA.");
export const MESSAGE_MUST_SELECT_MORE_THAN_ONE_ERRAND = I("* Please select more than one errand.");
export const MESSAGE_MUST_SELECT_ERRAND = I("* Please select errand.");
export const CLOSE_BROWSER_MANUALLY = I("Please close browser window manually");
export const PUZZEL = "puzzel",
	SOLIDUS = "solidus",
	CLEARIT = "clearinteract",
	ENGHOUSE = "enghouse",
	TELAVOX = "telavox",
	ZISSON = "zisson";

export const LIGHT_NOT_MINE = 1,
	LIGHT_NO_EE = 2,
	LIGHT_READY = 3;

export const AT_UPLOADED = 'uploaded',
	AT_BROWSE = AT_UPLOADED,
	AT_COL_ANS = 'collaboration-answer',
	AT_QUESTION = 'question',
	AT_SAVED = 'saved',
	AT_LIBRARY = 'library',
	AT_ARCHIVE = 'archive';

export const COL_MASK_LIGHT_ON = 1,
	COL_MASK_READ_ANSWER = 2,
	COL_MASK_DELIVERY_ERR = 4;

export const PRINT_COL_CONVERSATION = 1,
	PRINT_COL_MESSAGE = 2,
	PRINT_ERRANDS = 3;

export const PT_ERRAND = 'errand',
	PT_CHAT = 'chat',
	PT_COLLABORATION = 'collaboration';

const Errand_ACTION_CREATE = Action.CREATE // 1
	, Errand_ACTION_OPEN = Action.OPEN // 2
	, Errand_ACTION_ANSWER = Action.ANSWER // 3
	, Errand_ACTION_AGENT_FORWARD = Action.AGENT_FORWARD // 3
	, Errand_ACTION_AREA_FORWARD = Action.AREA_FORWARD // 4
	, Errand_ACTION_FOLDER_FORWARD = Action.FOLDER_FORWARD // 5
	 // 6
	, Errand_ACTION_DELETE = Action.DELETE // 7
	, Errand_ACTION_INBOX_RETURN = Action.INBOX_RETURN // 8
	, Errand_ACTION_RECYCLE = Action.RECYCLE // 9
	, Errand_ACTION_EXTERNAL_EXPERT_QUERY = Action.EXTERNAL_EXPERT_QUERY // 10
	, Errand_ACTION_EXTERNAL_EXPERT_ANSWER = Action.EXTERNAL_EXPERT_ANSWER // 11
	, Errand_ACTION_EXTERNAL_EXPERT_TURN_LIGHT_OFF = Action.EXTERNAL_EXPERT_TURN_LIGHT_OFF // 22
	, Errand_ACTION_CLONE = Action.CLONE //12
	, Errand_ACTION_RESEND = Action.RESEND // 13
	, Errand_ACTION_PUBLISH = Action.PUBLISH // 14
	, Errand_ACTION_UNPUBLISH = Action.UNPUBLISH // 15
	, Errand_ACTION_CLOSE = Action.CLOSE // 16
	, Errand_ACTION_STICKY = Action.STICKY // 17
	, Errand_ACTION_SAVE = Action.SAVE // 18
	, Errand_ACTION_EXTERNAL_FORWARD = Action.EXTERNAL_FORWARD // 19
	, Errand_ACTION_CHAT_FINISH = Action.CHAT_FINISH // 20
	, Errand_ACTION_CHAT_EXPIRE = Action.CHAT_EXPIRE // 21
	, Errand_ACTION_AREA_CHANGE = Action.AREA_CHANGE // 23
	, Errand_ACTION_REOPEN = Action.REOPEN // 24
	, Errand_ACTION_CHAT_AGENT_FORWARD = Action.CHAT_AGENT_FORWARD // 25
	, Errand_ACTION_CAMPAIGN_REPLAY = Action.CAMPAIGN_REPLAY // 26
	, Errand_ACTION_CHANGED_CHANNEL = Action.CHANGED_CHANNEL // 27
	, Errand_ACTION_WARNING_EMAIL = Action.WARNING_EMAIL // 30
	, Errand_ACTION_EXPIRED_EMAIL = Action.EXPIRED_EMAIL // 31
	, Errand_FACEBOOK_ACTION_LIKE = Action.FACEBOOK_LIKE // 32
	, Errand_FACEBOOK_ACTION_UNLIKE = Action.FACEBOOK_UNLIKE // 33
	, Errand_FACEBOOK_ACTION_HIDE = Action.FACEBOOK_HIDE // 34
	, Errand_FACEBOOK_ACTION_UNHIDE = Action.FACEBOOK_UNHIDE // 35
	, Errand_FACEBOOK_ACTION_DELETE = Action.FACEBOOK_DELETE // 36
	, Errand_ACTION_DONE_DATE = Action.DONE_DATE // 37
	, Errand_ACTION_EE_CLOSE_THREAD = Action.EE_CLOSE_THREAD // 38
	, Errand_FACEBOOK_ACTION_UPDATE_ANSWER = Action.FACEBOOK_UPDATE_ANSWER // 39
	, Errand_FACEBOOK_ACTION_DELETE_ANSWER = Action.FACEBOOK_DELETE_ANSWER // 40
	, Errand_TWITTER_RETWEET = Action.TWITTER_RETWEET // 3
	, Errand_TWITTER_ACTION_RETWEET = Action.TWITTER_ACTION_RETWEET // 41
	, Errand_ACTION_CHANGE_REPLY_TO = Action.CHANGE_REPLY_TO // 42
	, Errand_ACTION_ANONYMIZE = Action.ANONYMIZE // 43
	, Errand_ACTION_SOCIAL_ASSOCIATED_ANSWER = Action.SOCIAL_ASSOCIATED_ANSWER // 44
	, Errand_ACTION_CLOSE_NO_ANSWER = Action.CLOSE_NO_ANSWER // 46
	, Errand_ACTION_SIP_CALL_START = Action.SIP_CALL_START // 50
	, Errand_ACTION_SIP_CALL_END = Action.SIP_CALL_END // 51
	, Errand_ACTION_SIP_CALL_TRANSFER = Action.SIP_CALL_TRANSFER // 52
	, Errand_ACTION_UNKNOWN = Action.UNKNOWN // 100
	;
/* TODO: Create proper icons for
	Errand_ACTION_AREA_FORWARD,
	Errand_ACTION_FOLDER_FORWARD,
	Errand_ACTION_EXTERNAL_EXPERT_QUERY,
	Errand_ACTION_EXTERNAL_EXPERT_ANSWER,
	Errand_ACTION_EXTERNAL_EXPERT_TURN_LIGHT_OFF,
	Errand_ACTION_PUBLISH,
	Errand_ACTION_AREA_CHANGE,
	Errand_ACTION_CAMPAIGN_REPLAY,
	Errand_FACEBOOK_ACTION_UPDATE_ANSWER
	.. some more
*/
const ICON_MAIL_ACTION = "icon-v5-email";
export const ACTIONS_HISTORY_ICONS = {
	[Errand_ACTION_OPEN]: "icon-mail-arrow-down",
	[Errand_ACTION_ANSWER]: "icon-mail-arrow-left",
	[Errand_ACTION_AGENT_FORWARD]: "icon-member-arrow-right",
	[Errand_ACTION_AREA_FORWARD]: ICON_MAIL_ACTION,
	[Errand_ACTION_FOLDER_FORWARD]: ICON_MAIL_ACTION,
	[Errand_ACTION_DELETE]: "icon-times",
	[Errand_ACTION_INBOX_RETURN]: "icon-return",
	[Errand_ACTION_RECYCLE]: "icon-refresh",
	[Errand_ACTION_EXTERNAL_EXPERT_QUERY]: ICON_MAIL_ACTION,
	[Errand_ACTION_EXTERNAL_EXPERT_ANSWER]: ICON_MAIL_ACTION,
	[Errand_ACTION_EXTERNAL_EXPERT_TURN_LIGHT_OFF]: ICON_MAIL_ACTION,
	[Errand_ACTION_CLONE]: "icon-copy",
	[Errand_ACTION_RESEND]: "icon-mail-arrow-left",
	[Errand_ACTION_PUBLISH]: ICON_MAIL_ACTION,
	[Errand_ACTION_UNPUBLISH]: "icon-times",
	[Errand_ACTION_CLOSE]: ICON_MAIL_ACTION,
	[Errand_ACTION_CLOSE_NO_ANSWER]: ICON_MAIL_ACTION,
	[Errand_ACTION_STICKY]: "icon-pin",
	[Errand_ACTION_SAVE]: ICON_MAIL_ACTION,
	[Errand_ACTION_EXTERNAL_FORWARD]: ICON_MAIL_ACTION,
	[Errand_ACTION_CHAT_FINISH]: "icon-v5-chat",
	[Errand_ACTION_CHAT_EXPIRE]: "icon-v5-chat",
	[Errand_ACTION_REOPEN]: ICON_MAIL_ACTION,
	[Errand_ACTION_CHAT_AGENT_FORWARD]: "icon-member-arrow-right",
	[Errand_ACTION_AREA_CHANGE]: ICON_MAIL_ACTION,
	[Errand_ACTION_CAMPAIGN_REPLAY]: ICON_MAIL_ACTION,
	[Errand_ACTION_CHANGED_CHANNEL]: "icon-member-arrow-right",
	[Errand_ACTION_WARNING_EMAIL]: "icon-caution",
	[Errand_ACTION_EXPIRED_EMAIL]: "icon-clock",
	[Errand_FACEBOOK_ACTION_LIKE]: "icon-like",
	[Errand_FACEBOOK_ACTION_UNLIKE]: "icon-unlike",
	[Errand_FACEBOOK_ACTION_HIDE]: "icon-eye-blocked",
	[Errand_FACEBOOK_ACTION_UNHIDE]: "icon-eye",
	[Errand_FACEBOOK_ACTION_DELETE]: "icon-v5-facebook",
	[Errand_ACTION_SIP_CALL_START]: "icon-call",
	[Errand_ACTION_SIP_CALL_END]: "icon-call-end",
	[Errand_ACTION_SIP_CALL_TRANSFER]: "icon-forward-call",
	[Errand_ACTION_DONE_DATE]: "icon-mail-arrow-down",
	[Errand_ACTION_EE_CLOSE_THREAD]: "icon-circled-times",
	[Errand_FACEBOOK_ACTION_UPDATE_ANSWER]: "icon-v5-facebook",
	[Errand_FACEBOOK_ACTION_DELETE_ANSWER]: "icon-v5-facebook",
	[Errand_TWITTER_ACTION_RETWEET]: "icon-retweet",
	[Errand_TWITTER_RETWEET]: "icon-retweet",
	[Errand_ACTION_CHANGE_REPLY_TO]: "icon-mail-arrow-down",
	[Errand_ACTION_ANONYMIZE]: "icon-member-anon",
	[Errand_ACTION_UNKNOWN]: ICON_MAIL_ACTION,
	[Errand_ACTION_SOCIAL_ASSOCIATED_ANSWER]: "icon-mail-arrow-left"
};

/* Hotkey actions setup */
export const REFRESH = "Refresh"
	, TOGGLE_ALL = "Toggle All"
	, DELETE_SELECTED = "Delete Selected"
	, CLOSE_SELECTED = "Close Selected"
	, HKA_SAVE_AS_EML = "Save As EML"
	, SWITCH_VIEW = "Switch View"
	, NEXT_PAGE = "Next Page"
	, PREV_PAGE = "Prev Page"
	, NEXT_PAGE_SEARCH = "Search Next Page"
	, PREV_PAGE_SEARCH = "Search Prev Page"
	, CLEAR_SEARCH = "Clear Search"
	, START_SEARCH = "Start Search"
	, CLEAR_ANSWER = "Clear"
	, DELETE = "Delete"
	, TOGGLE_REPLY = "Toggle Reply"
	, TOGGLE_QUESTION = "Toggle Question"
	, TOGGLE_COMMENT = "Toggle Internal Comment"
	, TOGGLE_COLLAB = "Toggle Collaboration"
	, TOGGLE_FRWD_XTRNL = "Toggle Forward To External"
	, TOGGLE_IN_CHAT_COL = "Toggle Internal Chat Collaboration"
	, ATTACH_FILE = "Attach file"
	, RETURN_ERRAND = "Return Errand"
	, SPELLCHECK = "Spellcheck"
	, PREVIEW = "Preview"
	, SAVE = "Save"
	, SEND = "Send"
	, RESET = "Reset"
	, SEND_TO_ALL = "Send to all"
	, CLOSE = "Close"
	, DELETE_MESSAGE = "Delete Message"
	, NEW_MESSAGE = "New Message"
	, TOGGLE_ALL_MESSAGE = "Toggle All Message"
	, TOGGLE_CHAT_SUMMARY = "Toggle Chat Summary"
	, SEND_ERRAND = "Send Errand"
	;
/* Common shortcut applied to all views */
export const COMMON_HOTKEYS = [
	{key : "0", name: I("Logout"), type : "link", action : "logout"}, // TODO: bug here as can't logout on ../v5/xxx pages
	{key : "1", name: I("All Errands"), type : "handler", action : NEW_ERRANDS},
	{key : "2", name: I("My Errands"), type : "handler", action : MY_ERRANDS},
	{key : "3", name: I("Go To Search"), type: "handler", action: SEARCH_ERRANDS},
	{key : "4", name: I("Go To Statistics"), type : "handler", action : STATISTICS_PAGE},
	{key : "9", name: I("Go To Profile"), type : "link", action : "/ng/admin#agents/edit"}, // TODO: should not hardcoded ng
	{key : "H", name: I("Press A Key Combination"), type : "handler", action : "Enter"}
];
/* Workflow list hotkeys */
export const WORKFLOW_HOTKEYS = [
	{key : "U", name: I("Refresh"), type : "handler", action : REFRESH},
	{key : "D", name: I("Throw Selected"), type : "handler", action : DELETE_SELECTED},
	{key : "O", name: I("Close Selected"), type : "handler", action : CLOSE_SELECTED},
	{key : "I", name: I("Return Errands"), type : "handler", action : RETURN_ERRAND},
	{key : "V", name: I("Switch View"), type: "handler", action: SWITCH_VIEW}
];

export const WORKFLOW_HOTKEYS_LIST_VIEW = [
	{key : "U", name: I("Refresh"), type : "handler", action : REFRESH},
	{key : "T", name: I("Toggle All"), type : "handler", action : TOGGLE_ALL},
	{key : "D", name: I("Throw Selected"), type : "handler", action : DELETE_SELECTED},
	{key : "O", name: I("Close Selected"), type : "handler", action : CLOSE_SELECTED},
	{key : "I", name: I("Return Errands"), type : "handler", action : RETURN_ERRAND},
	{key : "V", name: I("Switch View"), type: "handler", action: SWITCH_VIEW},
	{key : "Right", name: I("Go To Next Page"), type : "handler", action : NEXT_PAGE},
	{key : "Left", name: I("Go To Previous Page"), type : "handler", action : PREV_PAGE}
];

/* Search hotkeys */
export const SEARCH_HOTKEYS = [
	{key : "C", name: I("Clear search"), type : "handler", action : CLEAR_SEARCH},
	{key : "S", name: I("Start search"), type : "handler", action : START_SEARCH},
	{key : "Right", name: I("Go To Next Page"), type : "handler", action : NEXT_PAGE_SEARCH},
	{key : "Left", name: I("Go To Previous Page"), type : "handler", action : PREV_PAGE_SEARCH}
];
/* Errand hotkeys */
export const ERRAND_HOTKEYS = [
	{key : "C", name: I("Clear"), type : "handler", action : CLEAR_ANSWER},
	{key : "D", name: I("Delete Errands"), type : "handler", action : DELETE},
	{key : "R", name: I("Toggle Reply"), type: "handler", action: TOGGLE_REPLY},
	{key : "M", name: I("Toggle Internal Comment"), type: "handler", action: TOGGLE_COMMENT},
	{key : "B", name: I("Toggle Collaboration"), type: "handler", action: TOGGLE_COLLAB},
	{key : "X", name: I("Toggle Forward To External"), type: "handler", action: TOGGLE_FRWD_XTRNL},
	{key : "F", name: I("Attach file"), type : "handler", action : ATTACH_FILE},
	{key : "I", name: I("Return Errands"), type : "handler", action : RETURN_ERRAND},
	{key : "J", name: I("Return To Search"), type : "handler", action : SEARCH_ERRANDS},
	{key : "K", name: I("Spell check"), type : "handler", action : SPELLCHECK},
	{key : "E", name: I("Save"), type : "handler", action : SAVE},
	{key : "S", name: I("Send"), type : "handler", action : SEND},
	{key : "T", name: I("Reset"), type : "handler", action : RESET},
	{key : "A", name: I("Send to all"), type : "handler", action : SEND_TO_ALL},
	{key : "O", name: I("Close Errand"), type : "handler", action: CLOSE},
	{key : "V", name: I("Switch View"), type: "handler", action: SWITCH_VIEW},
	{key : "W", name: I("Close with summary"), type: "handler", action: TOGGLE_CHAT_SUMMARY},
];

/* Manual errand hotkeys */
export const MANUAL_ERRAND_HOTKEYS = [
	{key : "C", name: I("Clear Answer"), type : "handler", action : CLEAR_ANSWER},
	{key : "F", name: I("Attach file"), type : "handler", action : ATTACH_FILE},
	{key : "K", name: I("Spell check"), type : "handler", action : SPELLCHECK},
	{key : "S", name: I("Create/Send"), type : "handler", action : SEND}
];

/* Chat Errand  hotkeys */
export const CHAT_ERRAND_HOTKEYS = [
	{key : "F", name: "Attach file", type : "handler", action : ATTACH_FILE},
	{key : "S", name: "Send", type : "handler", action : SEND},
	{key : "O", name: "Close", type : "handler", action: CLOSE}
];

// The values for these must correspond to the ones in chatSession.go
export const CHAT_crUnknown = 0;
export const CHAT_crOwner   = 1;
export const CHAT_crInvited = 2;
export const CHAT_crGuest   = 3;

// errand status
export const EST_UNKNOWN = 0
	, EST_OWNED = 1 // currently logged user owned the errand
	, EST_ACQUIRED = 2 // errand had been acquired by others but not closed/deleted
	, EST_HANDLED = 3 // errand had been closed/deleted
	;
/* Errand Acquire/Ownership Status */
export const STATUS_OWNED = I("Owned")
	, STATUS_ACQUIRED = I("Acquired")
	, STATUS_HANDLED = I("Handled")
	, STATUS_REVIEW = I("Review")
	, STATUS_NEW = I("New")
	;
/*Acquire errands contact*/
export const ACQ_NO_HISTORY = I('No history')
	, ACQ_HAS_HISTORY = I('Customer has history')
	, ACQ_HAS_OPEN_ERRAND = I('Customer has open errands')

export const TXT_CHECK_TAKEN_STATUS = I("Checking to see if the errand has been taken")
	, TXT_ERRAND_ALREADY_HANDLED_BY = I('This errand has already been handled by {0}.')
	, TXT_ERRAND_FROM_SEARCH_ANSWER = I('You have opened this errand from Search Answers.')
	, TXT_ERRAND_OPEN_FROM_FOLDER = I('This errand has been opened from folder {0} and it is owned by {1}.')
	, TXT_ERRAND_OWNED_BY_WHO = I('You have opened an errand owned by {0}.')
	, TXT_ERRAND_UNHANDLED = I('You have opened an unhandled errand owned by {0}.')
	, TXT_MANUAL_ERRAND = I("Starting errand.")
	, TXT_OWNED_BACK_TO_INBOX = I("This errand is owned by {0}, click here to return to the inbox")
	, TXT_OWNED_PICK_ANOTHER = I("This errand is owned by {0}, click here to pick another errand")
	, TXT_OWNER_TAKES_ERRAND = I("You have taken ownership of this errand. It will now appear in My Errands.")
	, TXT_REVIEW_ERRAND_OWNED_BY_WHO = I('You have opened an errand owned by {AGENT_NAME} for review.')
	, TXT_PENDING_REVIEW_ERRAND = I('This errand is owned by {AGENT_NAME} and is pending for review.')
	, TXT_OPEN_NEW_ERRAND = I('The errand is new in the system.')
	;
export const ACQUIRE_STATUS_MESSAGES = {
	CHECK_TAKEN_STATUS: TXT_CHECK_TAKEN_STATUS
	, ERRAND_ALREADY_HANDLED_BY: TXT_ERRAND_ALREADY_HANDLED_BY
	, ERRAND_FROM_SEARCH_ANSWER: TXT_ERRAND_FROM_SEARCH_ANSWER
	, ERRAND_OPEN_FROM_FOLDER: TXT_ERRAND_OPEN_FROM_FOLDER
	, ERRAND_OWNED_BY_WHO: TXT_ERRAND_OWNED_BY_WHO
	, ERRAND_UNHANDLED: TXT_ERRAND_UNHANDLED
	, MANUAL_ERRAND: TXT_MANUAL_ERRAND
	, OWNED_BACK_TO_INBOX: TXT_OWNED_BACK_TO_INBOX
	, OWNED_PICK_ANOTHER: TXT_OWNED_PICK_ANOTHER
	, OWNER_TAKES_ERRAND: TXT_OWNER_TAKES_ERRAND
};

// set current active left menu on statistics page
export const OVERVIEW_REPORT = 'OVERVIEW_REPORT'
	,EXECUTIVE_REPORT = 'EXECUTIVE_REPORT'
	,ORGANIZATION_REPORT = 'ORGANIZATION_REPORT'
	,SATISFACTION_METER_REPORT = 'SATISFACTION_METER_REPORT'
	,ERRANDS_REPORT = 'ERRANDS_REPORT'
	,OTHER_REPORT = 'other_report'
	,SCHEDULED_REPORT = 'SCHEDULED_REPORT'
	,EXPORTS_REPORT = 'EXPORTS_REPORT'
	,VIEW_REPORT = 'VIEW_REPORT'
	,CREATE_REPORT = 'CREATE_REPORT'
	,CONFIGURE_TIME = 'CONFIGURE_TIME'
	,LIVE_REPORT = 'LIVE_REPORT'
	,LIVE_REPORT_EMAIL = 'LIVE_REPORT_EMAIL'
	,LIVE_REPORT_CHAT = 'LIVE_REPORT_CHAT'
	,LIVE_REPORT_VOICE = 'LIVE_REPORT_VOICE'
	,LIVE_REPORT_OVERVIEW = 'LIVE_REPORT_OVERVIEW'
	;

//live report agent's status
export const INSESSION_STATUS = 15, INPROGRESS_STATUS = 14, ONHOLD_STATUS = 13, ONLINE_STATUS = -1, AWAY_STATUS = -2;

export const SYSTEM_REPORTS = "System reports"
, CUSTOM_REPORTS = "Other custom reports"
;

export const STATISTICS_TITLE = {
	OVERVIEW_REPORT: I("Overview of Statistics")
	,EXECUTIVE_REPORT: I("Executive Report")
	,ORGANIZATION_REPORT: I("Organization Report")
	,SATISFACTION_REPORT: I("Satisfaction Meter Report")
	,ERRANDS_REPORT: I("Errands Report")
	,[OTHER_REPORT]: I("Other Report")
	,SCHEDULED_REPORT: I("Scheduled reports")
	,EXPORTS_REPORT: I('Exports Report')
	,VIEW_REPORT: I('View Reports')
	,CREATE_REPORT: I('Create report')
	,CONFIGURE_TIME: I('Time Formats')
	,LIVE_REPORT: I('Live report')
	,LIVE_REPORT_EMAIL: I('Live email report')
	,LIVE_REPORT_CHAT: I('Live chat report')
	,LIVE_REPORT_VOICE: I('Live voice report')
	,LIVE_REPORT_OVERVIEW: I('Live overview report')
}

// menu id - TODO: NOTE.1 MUST match with back-end main menu id.
export const M_ADDRESSBOOK = "administration.organisations.addressbook"
	, M_AGENTS = "administration.agents"
	, M_CHATBOTS = "administration.chatbots"
	, M_EXTERNALEXPERT = "administration.organisations.externalexperts"
	, M_GROUPS = "administration.groups"
	, M_MY_ADDRESSBOOK = "myprofile.addressbook"
	, M_PREFERENCES = "myprofile.preferences"
	, M_MY_EXTERNALEXPERT = "myprofile.externalexperts"
	, M_MY_SALUTATION = "myprofile.salutations"
	, M_MY_SIGNATURE = "myprofile.signatures"
	, M_MY_WORDLIST = "myprofile.wordlists"
	, M_MYPROFILE = "myprofile"
	, M_FILEARCHIVE = "administration.organisations.filearchive"
	, M_ACCOUNTS = "administration.organisations.accounts"
	, M_ACC_EMAIL = "administration.organisations.accounts.email"
	, M_ACC_LINE = "administration.organisations.accounts.line"
	, M_ACC_FACEBOOK = "administration.organisations.accounts.facebook"
	, M_ACC_INSTAGRAM = "administration.organisations.accounts.instagram"
	, M_ACC_TWITTER = "administration.organisations.accounts.twitter"
	, M_ACC_LINKEDIN = "administration.organisations.accounts.linkedin"
	, M_ACC_JIRA = "administration.organisations.accounts.jira"
	, M_ACC_HUBSPOT = "administration.organisations.accounts.hubspot"
	, M_ACC_MSTEAMS = "administration.organisations.accounts.microsoft"
	, M_ACC_GOOGLEREVIEW = "administration.organisations.accounts.googlereview"
	, M_ACC_GOOGLECHAT = "administration.organisations.accounts.googlechat"
	, M_ACC_GOOGLEPLAY = "administration.organisations.accounts.googleplaystore"
	, M_ACC_TRUSTPILOT = "administration.organisations.accounts.trustpilot"
	, M_ACC_TELEGRAM = "administration.organisations.accounts.telegram"
	, M_ACC_WHATSAPP = "administration.organisations.accounts.whatsApp"
	, M_ACC_VIBER = "administration.organisations.accounts.viber"
	, M_ACC_SLACK = "administration.organisations.accounts.slack"
	, M_ACC_TWILIO = "administration.organisations.accounts.twilio"
	, M_ACC_YOUTUBE = "administration.organisations.accounts.youtube"
	, M_ACC_SMPP = "administration.organisations.accounts.smpp"
	, M_ACC_SMTP = "administration.organisations.accounts.smtp"
	, M_RECEIPT_GREETINGS = "administration.organisations.receiptgreetings"
	, M_ROUTING_KEYWORDS = "administration.organisations.routing.keywords"
	, M_ROUTING_AUTOTAGS = "administration.organisations.routing.autotags"
	, M_ROUTING_SIP = "administration.organisations.routing.sip"
	, M_REVIEW_KEYWORDS = "administration.reviewmanager.keywords"
	, M_REVIEW_AGENTS = "administration.reviewmanager.agents"
	, M_SALUTATION = "administration.organisations.salutations"
	, M_SIGNATURE = "administration.organisations.signatures"
	, M_SMTP = "administration.organisations.smtp"
	, M_TEMPLATE = "administration.organisations.templates"
	, M_QUICK_REPLY = "administration.organisations.quickreply"
	, M_TEMPLATE_WHATSAPP = "administration.organisations.templates.whatsapp"
	, M_KNOWLEDGEBASE = "administration.knowledgebases"
	, M_ROUTING_GROUPS = "administration.routinggroups"
	, M_COMPANIES = "administration.companies"
	, M_CONFIGCHANGELOG = "administration.configchangelog"
	, M_STUN_TURN = "administration.stunturn"
	, M_ERRANDINTERNALSTATE = "administration.organisations.errandinternalstate"
	, M_CHATWIDGETDL = "administration.chat-downloadwidget"
	, M_FAQWIDGETDL = "administration.faq-downloadwidget"
	, M_VOICEWIDGETDL = "administration.voice-downloadwidget"
	, M_AGENTSIPSTATUS = "administration.agentsipstatus"
	, M_CLASSIFIER = "administration.organisations.classifier"
	, M_TAG = "administration.organisations.tags"
	, M_ORGANISATIONV5 = "administration.organisationsv5"
	, M_CALL_RECORDINGS = "administration.call.recordings"
	, M_AGENT_SIP_LOGINS = "administration.call.agentsiplogins"
	, M_SIP_PRIORITY_NUMBERS = "administration.call.sipprioritynumbers"
	, M_CALL_IVR = "administration.call.ivr"
	, M_CALL_SIP_TRUNK = "administration.call.siptrunk"
	, M_CORS_WHITELIST = "administration.chat.cors"
	, M_CHAT_IP_BLACKLIST = "administration.chat.blacklist"
	, M_CONTACT_CARDS = "administration.clients"
	, M_API_CALLBACK = "administration.api.callback"
	, M_API_ACCESSTOKENS = "administration.api.accesstokens"
	, M_SKILLS = "administration.skills"
	, M_AGENT_ASSIST = "administration.agent.assist"
	, M_GENERATIVE_AI_DOCUMENT = "administration.organisations.generative-ai-document"
	// main menu id. see NOTE.1. Every V5 'page' - that has a quite different
	// component for react-router to render and has a link associated - MUST has
	// an id here.
	, MM_ADMINISTRATION = "administration"
	, MM_EXPORT = "exports"
	, MM_EXPORT_CALL = "call-exports"
	, MM_MESSAGE = "myprofile.internalmessage"
	, MM_SEARCH = "searcherrands"
	, MM_SEARCHANSWERS ="searchanswers"
	, MM_STATISTICS = "statistics"
	, MM_LIVE_REPORTS = "live-reports"
	, MM_WORKFLOW = "workflow"
	, WHATSAPP_INTERACTIVE = "administration.whatsapp.interactive"
	;
export const ADMINISTRATION = I("Administration")
	, ORGANIZATION_ADMIN = I('Organizations and areas')
	;
export const A_CHATBOTS = CHATBOTS
	, A_KNOWLEDGE_BASES = "knowledge-bases"
	, A_ROUTING_GROUPS = "routinggroups"
	, A_CLOSE_RECEIPTS = CLOSE_RECEIPTS
	, A_FEEDBACKS = FEEDBACKS
	, A_GREETINGS = GREETINGS
	, A_RECEIVE_RECEIPTS = RECEIVE_RECEIPTS
	, A_SMTP = SMTP
	, A_TEMPLATES_WHATSAPP = "whatsapp"
	, A_TAG = "tag"
	, A_ACCOUNTS="accounts"
	, A_ORGANISATIONS_V5="organisations"
	, A_CALL_RECORDINGS="callrecordings"
	, A_AGENT_SIP_LOGINS="agentsiplogins"
	, A_SIP_PRIORITY_NUMBERS="sipprioritynumbers"
	, A_CORS_WHITELIST="cors"
	, A_CHAT_IP_BLACKLIST="blacklist"
	, A_CONTACT_CARDS="contactcards"
	, A_GROUPS = "groups"
	, A_CALL_IVR = "ivr"
	, A_CALL_SIP_TRUNK = "siptrunk"
	, A_SKILLS = "skills"
	, A_AGENT_ASSIST = "agentassist"
	;
const A_REVIEW_KEYWORDS = "review-keywords"
	, A_REVIEW_AGENTS = "review-agents"
	, A_TEMPLATES = "templates"
	, A_QUICK_REPLY = "quickreply"
	;
const txtSalutations = I("Salutations")
	, txtSignatures = I("Signatures")
	, txtTemplates = I("Templates")
	, txtErrandInternalState = I("Errand State")
	, txtQuickReply = I("Quick Replies")
	;
export const TXT_DOWNLOADING_DATA = I('starting download')
export const TXT_DOWNLOAD_FAIL = I('download failed')
//Admin page title based on view
export const ADMIN_TITLE = {
	"Administration": I("Administration")
	, [M_GROUPS]: I("Groups")
	, [M_AGENTS]: I("Agents")
	, [M_CHATBOTS]: TXT_CHATBOTS
	, [M_RECEIPT_GREETINGS]: TXT_RECEIPT_GREETINGS
	, [M_MY_WORDLIST]: I("Word List")
	, [M_MY_SIGNATURE]: I("Personal Signatures")
	, [M_MY_SALUTATION]: I("Personal Salutations")
	, [M_REVIEW_KEYWORDS]: I("By Keywords")
	, [M_REVIEW_AGENTS]: I("By Agents")
	, [M_SIGNATURE]: txtSignatures
	, [M_SALUTATION]: txtSalutations
	, [M_SMTP]: TXT_SMTP
	, [M_ERRANDINTERNALSTATE]: txtErrandInternalState
	, [M_MY_EXTERNALEXPERT]: I("External Expert")
	, [M_EXTERNALEXPERT]: I("External Experts")
	, [M_FILEARCHIVE]: I("File Archive")
	, [M_ACCOUNTS]: I("Email")
	, [M_ACC_EMAIL]: I("Email")
	, [M_ACC_LINE]: I("LINE")
	, [M_ACC_FACEBOOK]: I("Facebook")
	, [M_ACC_INSTAGRAM]: I("Instagram")
	, [M_ACC_TWITTER]: I("Twitter")
	, [M_ACC_LINKEDIN]: I("LinkedIn")
	, [M_ACC_JIRA]: I("Jira")
	, [M_ACC_HUBSPOT]: I("HubSpot")
	, [M_ACC_MSTEAMS]: I("Microsoft Teams")
	, [M_ACC_GOOGLEREVIEW]: I("Google Review")
	, [M_ACC_GOOGLECHAT]: I("Google chat")
	, [M_ACC_GOOGLEPLAY]: I("Google Play")
	, [M_ACC_TRUSTPILOT]: I("Trustpilot")
	, [M_ACC_TELEGRAM]: I("Telegram")
	, [M_ACC_WHATSAPP]: I("Whatsapp")
	, [M_ACC_TWILIO]: I("Twilio")
	, [M_ACC_VIBER]: I("Viber")
	, [M_ACC_SLACK]: I("Slack")
	, [M_ACC_YOUTUBE]: I("Youtube")
	, [M_ACC_SMPP]: I("SMPP")
	, [M_ACC_SMTP]: I("SMTP")
	, [M_MY_ADDRESSBOOK]: I("Personal Address Book")
	, [M_ADDRESSBOOK]: I("Address book")
	, [M_KNOWLEDGEBASE]: I("Knowledge base")
	, [M_ROUTING_GROUPS]: I("Routing groups")
	, [M_TEMPLATE]: txtTemplates
	, [M_QUICK_REPLY]: txtQuickReply
	, [M_TEMPLATE_WHATSAPP]: txtTemplates
	, [M_COMPANIES]: I("Company")
	, [M_CONFIGCHANGELOG]: I("Audit Trails")
	, [M_STUN_TURN]: I("STUN/TURN server")
	, [M_ROUTING_KEYWORDS]: I("Area Routing List")
	, [M_ROUTING_AUTOTAGS]: I("Area Routing Tag")
	, [M_ROUTING_SIP]: I("Area Routing SIP")
	, [M_CHATWIDGETDL]: I("Live Chat Widget Configuration")
	, [M_FAQWIDGETDL]: I("Live FAQ Widget Configuration")
	, [M_VOICEWIDGETDL]: I("Callback Widget Configuration")
	, [M_AGENTSIPSTATUS]: I("Agent SIP Statuses")
	, [M_CLASSIFIER]: I("Document Classifiers")
	, [M_TAG]: I("Tags")
	, [M_ORGANISATIONV5]: I("Organizations and areas")
	, [M_CALL_RECORDINGS]: I("Call Recordings")
	, [M_AGENT_SIP_LOGINS]: I("Agent SIP Login")
	, [M_SIP_PRIORITY_NUMBERS]: I("SIP Priority Numbers")
	, [M_CALL_IVR]: ("IVR")
	, [M_CALL_SIP_TRUNK]: I("SIP Trunk")
	, [M_CORS_WHITELIST]: I("Cross-Origin Resource Sharing")
	, [M_CHAT_IP_BLACKLIST]: I("IP Address Blacklist")
	, [M_CONTACT_CARDS]: I("Contact Cards")
	, [M_API_CALLBACK] : I("Callback API")
	, [M_API_ACCESSTOKENS] : I("Access tokens")
	, [M_SKILLS] : I("Skills")
	, [M_AGENT_ASSIST] : I("Agent Assist")
	, [M_GENERATIVE_AI_DOCUMENT]: I("Context Documents")
};

export const ADMIN_EDIT_TITLE = {
	"Administration": I("Administration")
	, [M_GROUPS]: I("group")
	, [M_AGENTS]: I("agent")
	, [M_CHATBOTS]: TXT_CHATBOTS
	, [M_RECEIPT_GREETINGS]: TXT_RECEIPT_GREETINGS
	, [M_MY_WORDLIST]: I("Word List")
	, [M_MY_SIGNATURE]: I("Personal Signature")
	, [M_MY_SALUTATION]: I("Personal Salutation")
	, [M_REVIEW_KEYWORDS]: I("keyword")
	, [M_REVIEW_AGENTS]: I("agent")
	, [M_SIGNATURE]: I('signature')
	, [M_SALUTATION]: I('salutation')
	, [M_SMTP]: I('SMTP')
	, [M_ERRANDINTERNALSTATE]: I("errand state")
	, [M_MY_EXTERNALEXPERT]: I("external expert")
	, [M_EXTERNALEXPERT]: I("external expert")
	, [M_FILEARCHIVE]: I("file archive")
	, [M_ACCOUNTS]: I("Email")
	, [M_ACC_EMAIL]: I("Email")
	, [M_ACC_LINE]: I("LINE")
	, [M_ACC_FACEBOOK]: I("Facebook")
	, [M_ACC_INSTAGRAM]: I("Instagram")
	, [M_ACC_TWITTER]: I("Twitter")
	, [M_ACC_JIRA]: I("Jira")
	, [M_ACC_SMPP]: I("SMPP")
	, [M_ACC_SMTP]: I("SMTP")
	, [M_ACC_HUBSPOT]: I("HubSpot")
	, [M_ACC_MSTEAMS]: I("Microsoft Teams")
	, [M_ACC_GOOGLEREVIEW]: I("Google Review")
	, [M_ACC_GOOGLECHAT]: I("Google Chat")
	, [M_ACC_GOOGLEPLAY]: I("Google Play")
	, [M_ACC_TRUSTPILOT]: I("Trustpilot")
	, [M_ACC_TELEGRAM]: I("Telegram")
	, [M_ACC_WHATSAPP]: I("WhatsApp")
	, [M_ACC_TWILIO]: I("Twilio")
	, [M_ACC_VIBER]: I("Viber")
	, [M_ACC_SLACK]: I("Slack")
	, [M_ACC_YOUTUBE]: I("Youtube")
	, [M_MY_ADDRESSBOOK]: I("Personal Address Book")
	, [M_ADDRESSBOOK]: I("address book")
	, [M_KNOWLEDGEBASE]: I("Knowledge base")
	, [M_ROUTING_GROUPS]: I("Routing groups")
	, [M_TEMPLATE]: I("template")
	, [M_TEMPLATE_WHATSAPP]: I("template")
	, [M_COMPANIES]: I("company")
	, [M_CONFIGCHANGELOG]: I("Audit Trails")
	, [M_STUN_TURN]: I("server")
	, [M_ROUTING_KEYWORDS]: I("area routing list")
	, [M_ROUTING_AUTOTAGS]: I("area routing tag")
	, [M_ROUTING_SIP]: I("area routing SIP")
	, [M_CHATWIDGETDL]: I("chat widget configuration")
	, [M_FAQWIDGETDL]: I("FAQ widget configuration")
	, [M_VOICEWIDGETDL]: I("Callback Widget Configuration")
	, [M_AGENTSIPSTATUS]: I("Agent SIP Status")
	, [M_CLASSIFIER]: I("Document Classifier")
	, [M_QUICK_REPLY]: I("quick reply")
	, [M_TAG]: I("tag")
	, [M_CALL_RECORDINGS]: I("Preview")
	, [M_AGENT_SIP_LOGINS]: I("Agent SIP Login")
	, [M_SIP_PRIORITY_NUMBERS]: I("SIP Priority Numbers")
	, [M_CALL_IVR]: I("IVR")
	, [M_CALL_SIP_TRUNK]: I("SIP Trunk")
	, [M_CONTACT_CARDS]: I("contact card")
	, [M_API_CALLBACK]: I("callback API")
	, [M_API_ACCESSTOKENS]: I("Access tokens")
	, [M_SKILLS]: I("skill")
	, [M_GENERATIVE_AI_DOCUMENT]: I("New context document")
};

export const FILTER_BY_SELECT = 1
	, FILTER_BY_TEXT = 2
	, FILTER_BY_AREA = 3
	, FILTER_BY_AGENT_STATUS = 4
	, FILTER_BY_AGENTS = 5
	, FILTER_BY_AGENT_SIP_STATUS = 6
	, FILTER_BY_DATE_BETWEEN = 7
	;
// Admin view constants using menu id
export const ADMIN_VIEW_MAP = {
	agents: M_AGENTS
	, wordlist: M_MY_WORDLIST
	, [A_CHATBOTS]: M_CHATBOTS
	, [A_CLOSE_RECEIPTS]: M_RECEIPT_GREETINGS
	, [A_FEEDBACKS]: M_RECEIPT_GREETINGS
	, [A_GREETINGS]: M_RECEIPT_GREETINGS
	, [A_RECEIVE_RECEIPTS]: M_RECEIPT_GREETINGS
	, [A_REVIEW_KEYWORDS]: M_REVIEW_KEYWORDS
	, [A_REVIEW_AGENTS]: M_REVIEW_AGENTS
	, [A_SMTP]: M_SMTP
	, "signatures-personal": M_MY_SIGNATURE
	, "signatures": M_SIGNATURE
	, "salutations-personal": M_MY_SALUTATION
	, "salutations": M_SALUTATION
	, "errandinternalstate": M_ERRANDINTERNALSTATE
	, "addressbook-personal": M_MY_ADDRESSBOOK
	, "addressbook": M_ADDRESSBOOK
	, "externalexpert-personal": M_MY_EXTERNALEXPERT
	, "externalexpert": M_EXTERNALEXPERT
	, "filearchive": M_FILEARCHIVE
	, "accounts": M_ACCOUNTS
	, "email": M_ACC_EMAIL
	, "line": M_ACC_LINE
	, "instagram": M_ACC_INSTAGRAM
	, "twitter": M_ACC_TWITTER
	, "linkedin": M_ACC_LINKEDIN
	, "jira": M_ACC_JIRA
	, "hubspot": M_ACC_HUBSPOT
	, "microsoft": M_ACC_MSTEAMS
	, "googlereview": M_ACC_GOOGLEREVIEW
	, "googlechat": M_ACC_GOOGLECHAT
	, "googleplaystore": M_ACC_GOOGLEPLAY
	, "smppconfiguration": M_ACC_SMPP
	, "smtp": M_ACC_SMTP
	, "trustpilot": M_ACC_TRUSTPILOT
	, "telegram": M_ACC_TELEGRAM
	, "whatsApp": M_ACC_WHATSAPP //todo: fix name conflict & rename appropriately
	, "twilio": M_ACC_TWILIO
	, "viber": M_ACC_VIBER
	, "slack": M_ACC_SLACK
	, "youtube": M_ACC_YOUTUBE
	, "facebook": M_ACC_FACEBOOK
	, "keywords": M_ROUTING_KEYWORDS
	, "autotags": M_ROUTING_AUTOTAGS
	, "sip": M_ROUTING_SIP
	, [A_KNOWLEDGE_BASES]: M_KNOWLEDGEBASE
	, [A_ROUTING_GROUPS]: M_ROUTING_GROUPS
	, [A_QUICK_REPLY]: M_QUICK_REPLY
	, [A_TEMPLATES]: M_TEMPLATE
	, [A_TEMPLATES_WHATSAPP]: M_TEMPLATE_WHATSAPP //todo: fix name conflict & rename appropriately
	, "companies": M_COMPANIES
	, "configchangelog" : M_CONFIGCHANGELOG
	, "stunturn": M_STUN_TURN
	, "chat-downloadwidget": M_CHATWIDGETDL
	, "faq-downloadwidget": M_FAQWIDGETDL
	, "voice-downloadwidget": M_VOICEWIDGETDL
	, "tag": M_TAG
	, [A_ORGANISATIONS_V5]: M_ORGANISATIONV5
	, [A_CALL_RECORDINGS]: M_CALL_RECORDINGS
	, [A_AGENT_SIP_LOGINS]: M_AGENT_SIP_LOGINS
	, [A_SIP_PRIORITY_NUMBERS]: M_SIP_PRIORITY_NUMBERS
	, [A_CORS_WHITELIST]: M_CORS_WHITELIST
	, [A_CHAT_IP_BLACKLIST]: M_CHAT_IP_BLACKLIST
	, [A_CONTACT_CARDS]: M_CONTACT_CARDS
	, [A_GROUPS]: M_GROUPS
	, [A_CALL_IVR]: M_CALL_IVR
	, [A_CALL_SIP_TRUNK]: M_CALL_SIP_TRUNK
	, [A_SKILLS]: M_SKILLS
	, [A_AGENT_ASSIST]: M_AGENT_ASSIST
	, "genaidocument": M_GENERATIVE_AI_DOCUMENT
};

export const UPLOAD_AGENT_STARTED = 1, UPLOAD_AGENT_INPROGRESS = 2, UPLOAD_AGENT_FINISH = 3;

const signatureFormConstantData = {
		label: txtSignatures
		, helperTxt: I("Insert template to the body of the signature message, [signature name] for agent signature name, [signature picture] for agent profile picture.")
	}
	, salutationFormConstantData = {label: txtSalutations}
	, errandStatusFormConstantData = {label: txtErrandInternalState}
	;
// admin form constant data
export const A_FORM_DATA = {
	[M_SALUTATION]: salutationFormConstantData
	, [M_SIGNATURE]: signatureFormConstantData
	, [M_MY_SALUTATION]: salutationFormConstantData
	, [M_MY_SIGNATURE]: signatureFormConstantData
	, [M_TEMPLATE]: {label: txtTemplates}
	, [M_QUICK_REPLY]: {label: txtQuickReply}
	, [M_TEMPLATE_WHATSAPP]: {label: txtTemplates}
	, [M_ERRANDINTERNALSTATE]: {label: txtErrandInternalState}
};

// statistics constants
export const RT_TABLE = 4
	, RT_CHART = 6
	, REPORT_TYPES = {
		[RT_TABLE]: I("Table")
		, [RT_CHART]: I("Chart")
		, order: [RT_TABLE, RT_CHART]
	}
	, DT_RELATIVE = 1
	, DT_ABSOLUTE = 2
	, DATE_TYPES = {
		[DT_RELATIVE]: I("Relative")
		, [DT_ABSOLUTE]: I("Absolute")
		, order: [DT_RELATIVE, DT_ABSOLUTE]
	}
	, RDT_SAME_DAY = 1
	, RDT_PREVIOUS_DAY = 2
	, RDT_SAME_WEEK = 3
	, RDT_SAME_MONTH = 4
	, RDT_PREVIOUS_WEEK = 5
	, RDT_PREVIOUS_MONTH = 6
	, RDT_CUSTOM = 7
	, RELATIVE_DATE_TYPES = {
		[RDT_SAME_DAY]: I("Same day")
		, [RDT_PREVIOUS_DAY]: I("Previous day")
		, [RDT_SAME_WEEK]: I("Same week")
		, [RDT_PREVIOUS_WEEK]: I("Previous week")
		, [RDT_SAME_MONTH]: I("Same month")
		, [RDT_PREVIOUS_MONTH]: I("Previous month")
		, order: [
			RDT_SAME_DAY
			, RDT_PREVIOUS_DAY
			, RDT_SAME_WEEK
			, RDT_PREVIOUS_WEEK
			, RDT_SAME_MONTH
			, RDT_PREVIOUS_MONTH
		]
	}
	, RELATIVE_DATE_TYPES_WITH_CUSTOM = {
		[RDT_SAME_DAY]: I("Same day")
		, [RDT_PREVIOUS_DAY]: I("Previous day")
		, [RDT_SAME_WEEK]: I("Same week")
		, [RDT_PREVIOUS_WEEK]: I("Previous week")
		, [RDT_SAME_MONTH]: I("Same month")
		, [RDT_PREVIOUS_MONTH]: I("Previous month")
		, [RDT_CUSTOM]: I("Custom")
		, order: [
			RDT_CUSTOM
			, RDT_SAME_DAY
			, RDT_PREVIOUS_DAY
			, RDT_SAME_WEEK
			, RDT_PREVIOUS_WEEK
			, RDT_SAME_MONTH
			, RDT_PREVIOUS_MONTH
		]
	}
	, RDT_EVERY_DAY = 1
	, RDT_EVERY_MONDAY = 2
	, RDT_EVERY_TUESDAY = 3
	, RDT_EVERY_WEDNESDAY = 4
	, RDT_EVERY_THURSDAY = 5
	, RDT_EVERY_FRIDAY = 6
	, RDT_EVERY_SATURDAY = 7
	, RDT_EVERY_SUNDAY = 8
	, RDT_BEGINNING_OF_MONTH = 9
	, REPORT_RUNNING_DAY = {
		[RDT_EVERY_DAY]: I("Every day")
		, [RDT_EVERY_MONDAY]: I("Every Monday")
		, [RDT_EVERY_TUESDAY]: I("Every Tuesday")
		, [RDT_EVERY_WEDNESDAY]: I("Every Wednesday")
		, [RDT_EVERY_THURSDAY]: I("Every Thursday")
		, [RDT_EVERY_FRIDAY]: I("Every Friday")
		, [RDT_EVERY_SATURDAY]: I("Every Saturday")
		, [RDT_EVERY_SUNDAY]: I("Every Sunday")
		, [RDT_BEGINNING_OF_MONTH]: I("Beginning of each month")
		, order: [
			RDT_EVERY_DAY
			, RDT_EVERY_MONDAY
			, RDT_EVERY_TUESDAY
			, RDT_EVERY_WEDNESDAY
			, RDT_EVERY_THURSDAY
			, RDT_EVERY_FRIDAY
			, RDT_EVERY_SATURDAY
			, RDT_EVERY_SUNDAY
			, RDT_BEGINNING_OF_MONTH
		]
	}
	, AT_ALL = 4
	, AT_ALL_ACTIVE = 3
	, AT_ALL_INACTIVE = 2
	, AT_CHOOSE_GROUPS = 1
	, AT_CHOOSE_AGENTS = 0
	, AGENT_TYPES = {
		[AT_ALL]: I("All Agents")
		, [AT_ALL_ACTIVE]: I("All Active Agents")
		, [AT_ALL_INACTIVE]: I("All Inactive Agents")
		, [AT_CHOOSE_GROUPS]: I("Choose Groups")
		, [AT_CHOOSE_AGENTS]: I("Choose Agents")
		, order: [
			AT_ALL
			, AT_ALL_ACTIVE
			, AT_ALL_INACTIVE
			, AT_CHOOSE_AGENTS
			, AT_CHOOSE_GROUPS
		]
	}
	, ET_EXCEL = 0
	, ET_INLINE = 1
	, ET_CSV = 2
	, ET_PDF = 3
	, EXPORT_TYPES = {
		[ET_EXCEL]: I("Excel")
		, [ET_CSV]: I("CSV")
		, [ET_INLINE]: I("Inline")
		, [ET_PDF]: I("Pdf")
		, order: [
			ET_EXCEL
			, ET_CSV
			, ET_INLINE
		]
	}
	, AV_ALL = 0
	, AV_ONLYME = 1
	, AVAILABILITY_TYPES = {
		[AV_ALL]: I("Everyone")
		, [AV_ONLYME]: I("Only me")
		, order: [
			AV_ALL
			, AV_ONLYME
		]
	}
	, SHARE_STATISTICS = 'SHARE_STATISTICS'
	, DT_ERRANDS = 1
	, DT_CONVERSATIONS = 2
	, AGGREGATE_TYPE = {
		[DT_ERRANDS]: I("Errands")
		, [DT_CONVERSATIONS]: I("Conversations")
		, order: [DT_ERRANDS, DT_CONVERSATIONS]
	}
	, DATE_COMPARE = {
		"Type": {
			[DT_RELATIVE]: I("Relative")
			, [DT_ABSOLUTE]: I("Absolute")
			, order: [DT_RELATIVE, DT_ABSOLUTE]
		},
		"Relative": {
			[RDT_SAME_DAY]: I("Same day")
			, [RDT_PREVIOUS_DAY]: I("Previous day")
			, [RDT_SAME_WEEK]: I("Same week")
			, [RDT_PREVIOUS_WEEK]: I("Previous week")
			, [RDT_SAME_MONTH]: I("Same month")
			, [RDT_PREVIOUS_MONTH]: I("Previous month")
			, [RDT_CUSTOM]: I("Custom")
			, order: [
				RDT_CUSTOM
				, RDT_SAME_DAY
				, RDT_PREVIOUS_DAY
				, RDT_SAME_WEEK
				, RDT_PREVIOUS_WEEK
				, RDT_SAME_MONTH
				, RDT_PREVIOUS_MONTH
			]
		}
	}
	;
// overview page layout
export const OL_LEFT_PANE = 0
	, OL_MID_PANE = 1
	, OL_RIGHT_PANE = 2
	, OC_LIMITS = {
		[OL_LEFT_PANE]: 13
		, [OL_MID_PANE]: 2
		, [OL_RIGHT_PANE]: 1
	}
	;
// overview page's charts source/type
export const CS_PIN = 0
	, CS_REPORT = 1
	, CS_SPECIAL = 2
	, CS_EXIST = 3
	;
// overview chart identifier - this can NOT be integer or string integer because
// it clashes with pin-report ID. The identifier can not clash with chart type
// matches' keys too (see below). Make this string as short as possible as it'll
// be used as url-query. Every item in this list will need special async action
// and selector to handle them because the reason they appear in this list is
// they can not be handled by standard view-report endpoint.
export const OC_ACTIVE_AGENTS = 'active'
	, OC_LEADERBOARD = 'leader'
	, OC_ORGANISATION = 'org'
	;
// leader board type.
export const LT_CLOSED = 1
	, LT_HANDLING_TIME = 2
	, LT_HANDLING_AND_CLOSED = 3
	;
// chart type identifier - identify which chartjs or custom chart.
// NOTE: give its name below
export const CT_GENERAL = 1
	, CT_GENERAL_TOTAL = 2
	, CT_GENERAL_TOTAL_AGENT = 3
	, CT_GENERAL_TOTAL_BIG = 4
	, CT_GENERAL_TOTAL_TIME = 5
	, CT_DOUGHNUT = 6
	, CT_FB_RATING = 7
	, CT_LEADERBOARD = 8
	, CT_ORG_BAR = 9
	, CT_SUMMARY_HORZ_BAR = 10 // summarized all groups
	, CT_GENERAL_BAR = 11
	, CT_GENERAL_TABLE = 12
	, CT_EXPANDABLE_TABLE = 13
	, CT_MULTI_TOTAL = 14
	, CT_LINE = 15
	, CT_VERT_BAR = 16
	, CT_PIE = 17
	, CT_CONTACTSTOPLIST = 18
	;
export const CHART_TYPE_NAMES = {
	[CT_CONTACTSTOPLIST]: I("Top List Contacts")
	, [CT_DOUGHNUT]: I("Doughnut")
	, [CT_EXPANDABLE_TABLE]: I("Expandable table")
	, [CT_FB_RATING]: I("Facebook rating")
	, [CT_GENERAL]: I("General bar")
	, [CT_GENERAL_BAR]: I("General bar")
	, [CT_GENERAL_TABLE]: I("Table")
	, [CT_GENERAL_TOTAL]: I("Total")
	, [CT_GENERAL_TOTAL_AGENT]: I("Total Agent")
	, [CT_GENERAL_TOTAL_BIG]: I("Large font total")
	, [CT_GENERAL_TOTAL_TIME]: I("Total time")
	, [CT_LEADERBOARD]: I("Leaderboard")
	, [CT_LINE]: I("Line chart")
	, [CT_MULTI_TOTAL]: I("Multi Total")
	, [CT_ORG_BAR]: I("Organization bar")
	, [CT_PIE]: I("Pie chart")
	, [CT_SUMMARY_HORZ_BAR]: I("Summary horizontal bar")
	, [CT_VERT_BAR]: I("Vertical bar")
};
// chart rating
export const CR_BAD = 1
	, CR_AVERAGE = 2
	, CR_GOOD = 3
	;
export const RATING_CLASSNAME = {
	[CR_BAD]: 'bad'
	, [CR_AVERAGE]: 'average'
	, [CR_GOOD]: 'good'
};

export const RATING_WORD = {
	[CR_BAD]: I('Bad')
	, [CR_AVERAGE]: I('Average')
	, [CR_GOOD]: I('Good!')
};

// system report name - TODO: parse from backend
export const SR_INCOMING = "Incoming"
	, SR_CHAT_SESSIONS = "Chat Sessions"
	, SR_CLOSED = "Closed"
	, SR_REPLIES = "Replies"
	, SR_AVG_RESP_TIME = "Average response time"
	, SR_AVG_UNANS_TIME = "Average unanswered time"
	, SR_AVG_HANDLE_TIME = "Average handling time"
	, SR_CLOSE_STATUS = "Closure status"
	, SR_SLR = "SLA time"
	, SR_HANDLE_TIME = "Handling time"
	, SR_RESP_TIME = "Response time"
	, SR_CLASSIFY_AREA = "Classification/Area"
	, SR_CLASSIFY_CHANNEL = "Classification/Channel"
	, SR_DEL_ERRANDS = "Deleted errands"
	, SR_INC_CHAT_REQ = "Incoming chat requests"
	, SR_SATISFY_METER = "Satisfaction meter"
	, SR_USER_ACTIVITY = "User Activity"
	, SR_AVR_CHAT_MSG_RESP_TIME = "Average chat message response time"
	, SR_AVR_CHAT_RESP_TIME = "Average chat response time"
	, SR_CAP_UTIL_CHAT = "Capacity Utilization (Chat)"
	, SR_CLOSED_UNANSWERED = "Closed/Unanswered"
	, SR_ORG_OVERVIEW = "Organization Overview"
	, SR_ACTIVE_AGENT = "Active Agent"
	, SR_FACEBOOK_RATING = "Facebook Ratings"
	, SR_LEADERBOARD = "Leaderboard"
	, SR_COLLABORATION = 'Collaboration'
	, SR_NUMBER_OF_CONTACTS = 'Number of Contacts'
	, SR_CLOSED_AND_ANSWERED = 'Closed and answered'
	, SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP = 'Answered and average handling time by agent group'
	, SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION = 'Answered and average handling time by organization'
	, SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP = 'Closed voice errand and average handling time by agent group'
	, SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION = 'Closed voice errand and average handling time by organization'
	, SR_CHAT_RATING = "Chat rating"
	, SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP = 'Answered and average calls time by agent group'
	, SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION = 'Answered and average calls time by organization'
	, SR_INBOUND_AND_OUTBOUND_CALLS = 'Inbound and outbound calls'
	, SR_SIP_MISSED_CALLS = 'SIP missed calls'
	, SR_SIP_ABANDONED_CALLS = 'SIP abandoned calls'
	;
// Notes: TODO This needed to be re-done based on the report keys
// and which chart type/s it most suits to show in executive report
// similar to CT_MATCHES but CT_MATCHES is one to one.
// The array should be arranged base on report page view top-left chart first.
export const WIDGET_CHART_TYPE = {
	[SR_INCOMING]: [CT_LINE]
	, [SR_CHAT_SESSIONS]: [CT_VERT_BAR, CT_GENERAL_TOTAL]
	, [SR_CLOSED]: [CT_VERT_BAR, CT_GENERAL_TOTAL_BIG, CT_GENERAL_TOTAL]
	, [SR_REPLIES]: [CT_VERT_BAR]
	, [SR_AVG_RESP_TIME]: [CT_VERT_BAR]
	, [SR_AVG_UNANS_TIME]: [CT_VERT_BAR]
	, [SR_AVG_HANDLE_TIME]: [CT_VERT_BAR, CT_GENERAL_TOTAL_TIME]
	, [SR_CLOSE_STATUS]: [CT_VERT_BAR]
	, [SR_SLR]: [CT_LINE]
	, [SR_HANDLE_TIME]: [CT_LINE]
	, [SR_RESP_TIME]: [CT_VERT_BAR]
	, [SR_CLASSIFY_AREA]: [CT_VERT_BAR]
	, [SR_CLASSIFY_CHANNEL]: [CT_VERT_BAR]
	, [SR_DEL_ERRANDS]: [CT_VERT_BAR]
	, [SR_INC_CHAT_REQ]: [CT_LINE]
	, [SR_SATISFY_METER]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_USER_ACTIVITY]: [CT_VERT_BAR]
	, [SR_AVR_CHAT_MSG_RESP_TIME]: [CT_VERT_BAR]
	, [SR_AVR_CHAT_RESP_TIME]: [CT_VERT_BAR]
	, [SR_CAP_UTIL_CHAT]: [CT_VERT_BAR]
	, [SR_CLOSED_UNANSWERED]: [CT_LINE]
	, [SR_ORG_OVERVIEW]: [CT_VERT_BAR, CT_ORG_BAR, CT_GENERAL_TOTAL]
	, [SR_ACTIVE_AGENT]: [CT_VERT_BAR, CT_MULTI_TOTAL]
	, [SR_FACEBOOK_RATING]: [CT_VERT_BAR, CT_FB_RATING, CT_DOUGHNUT]
	, [SR_LEADERBOARD]: [CT_VERT_BAR, CT_LEADERBOARD]
	, [SR_COLLABORATION]: [CT_VERT_BAR, CT_GENERAL_TOTAL]
	, [SR_NUMBER_OF_CONTACTS]: [CT_VERT_BAR, CT_CONTACTSTOPLIST]
	, [SR_CLOSED_AND_ANSWERED]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION]: [CT_VERT_BAR, CT_DOUGHNUT]
	, [SR_SIP_MISSED_CALLS]: [CT_LINE]
	, [SR_SIP_ABANDONED_CALLS]: [CT_VERT_BAR]
};
// report keys - TODO: parse from backend
const RK_AVG_CHAT_MSG_RESP_TIME = "average_chat_message_response_time"
	, RK_AVG_CHAT_RESP_TIME = "average_chat_response_time"
	, RK_AVG_CLBRTN_RSPS_TIME = "average_collaboration_response_time"
	, RK_AVG_CLBRTN_WAIT_TIME = "average_collaboration_waiting_time"
	, RK_AVG_ERD_PRCS_TIME = "average_errand_process_time"
	, RK_AVG_ERD_RSPS_TIME = "average_errand_response_time"
	, RK_AVG_ERD_UNANSWRD_TIME = "average_errand_unanswered_time"
	, RK_ERD_PRCS_TIME = "errand_process_time" // sum
	, RK_ERD_RSPS_TIME = "errand_response_time" // sum
	, RK_QUEUE_CHATS = "queue_chats"
	, RK_USR_ACTVTY_AVLBL = "user_activity_available" // sum
	, RK_USR_ACTVTY_AVLBL_CHAT = "user_activity_available_chat" // sum
	, RK_USR_ACTVTY_AWY = "user_activity_away" // sum
	, RK_USR_ACTVTY_BRK = "user_activity_break" // sum
	;
export const MAP_NO_AGG_DRILLDOWN = {
	[RK_AVG_CHAT_MSG_RESP_TIME]: true
	, [RK_AVG_CHAT_RESP_TIME]: true
	, [RK_AVG_CLBRTN_RSPS_TIME]: true
	, [RK_AVG_CLBRTN_WAIT_TIME]: true
	, [RK_AVG_ERD_PRCS_TIME]: true
	, [RK_AVG_ERD_RSPS_TIME]: true
	, [RK_AVG_ERD_UNANSWRD_TIME]: true
};
// chart type identifier - this may need to be parsed from backend. It determines
// which special report match which charjs type. General reports (which can be
// saved with pin report API) is identified by its key - which is the string
// value set to 'Identifier' during key struct init at golang backend. Thus,
// this constant only affect executive-report page (dashboard) chart type when
// it is pinned-report or specific report (system or custom).
export const CT_MATCHES = {
	incoming: CT_GENERAL_TOTAL
	, closed: CT_GENERAL_TOTAL_BIG
	, replies: CT_GENERAL_TOTAL_BIG
	, answer: CT_GENERAL_TOTAL_BIG
	, [RK_AVG_ERD_RSPS_TIME]: CT_GENERAL_TOTAL_TIME
	, average_errand_response_time_in_seconds: CT_GENERAL_TOTAL_TIME
	, average_errand_response_time_in_minutes: CT_GENERAL_TOTAL_TIME
	, average_errand_response_time_in_hours: CT_GENERAL_TOTAL_TIME
	, average_errand_response_time_in_days: CT_GENERAL_TOTAL_TIME
	, [RK_AVG_ERD_PRCS_TIME]: CT_GENERAL_TOTAL_TIME
	, average_errand_process_time_in_seconds: CT_GENERAL_TOTAL_TIME
	, average_errand_process_time_in_minutes: CT_GENERAL_TOTAL_TIME
	, average_errand_process_in_hours: CT_GENERAL_TOTAL_TIME
	, average_errand_process_in_days: CT_GENERAL_TOTAL_TIME
	, [RK_ERD_PRCS_TIME]: CT_GENERAL_TOTAL
	, total_errand_process_time: CT_GENERAL_TOTAL_TIME
	, [RK_ERD_RSPS_TIME]: CT_GENERAL_TOTAL
	, errand_classification: CT_GENERAL_TOTAL
	, errand_closure_status: CT_GENERAL_TOTAL
	, errand_closure_status_answered: CT_GENERAL_TOTAL
	, errand_closure_status_answered_with_resent: CT_GENERAL_TOTAL
	, errand_closure_status_unanswered: CT_GENERAL_TOTAL
	, errand_closure_status_deleted: CT_GENERAL_TOTAL
	, errand_closure_status_returned: CT_GENERAL_TOTAL
	, errand_closure_status_forward_to_area: CT_GENERAL_TOTAL
	, errand_closure_status_forward_to_agent: CT_GENERAL_TOTAL
	, errand_closure_status_moved_to_folder: CT_GENERAL_TOTAL
	, errand_closure_status_chat_finished: CT_GENERAL_TOTAL
	, errand_closure_status_chat_expired: CT_GENERAL_TOTAL
	, errand_closure_status_resent: CT_GENERAL_TOTAL
	, errand_closure_status_forward_to_external: CT_GENERAL_TOTAL
	, errands_responeded_according_to_sla_time: CT_GENERAL_TOTAL
	, errands_responeded_after_sla_time: CT_GENERAL_TOTAL
	, accepted_chat_requests: CT_GENERAL_TOTAL
	, rejected_closed_chat_requests: CT_GENERAL_TOTAL
	, rejected_unavailable_chat_requests: CT_GENERAL_TOTAL
	, received_satisfaction_meter: CT_DOUGHNUT
	, chose_satisfaction_meter_answer1: CT_GENERAL_TOTAL
	, chose_satisfaction_meter_answer2: CT_GENERAL_TOTAL
	, chose_satisfaction_meter_answer3: CT_GENERAL_TOTAL
	, chose_satisfaction_meter_answer4: CT_GENERAL_TOTAL
	, chose_satisfaction_meter_answer5: CT_GENERAL_TOTAL
	, load_incoming: CT_GENERAL_TOTAL
	, load_replies: CT_GENERAL_TOTAL
	, load_closed: CT_GENERAL_TOTAL
	, [RK_QUEUE_CHATS]: CT_GENERAL_TOTAL
	, [RK_USR_ACTVTY_AVLBL]: CT_GENERAL_TOTAL
	, [RK_USR_ACTVTY_AWY]: CT_GENERAL_TOTAL
	, [RK_USR_ACTVTY_BRK]: CT_GENERAL_TOTAL
	, [RK_USR_ACTVTY_AVLBL_CHAT]: CT_GENERAL_TOTAL
	, chat_capacity: CT_GENERAL_TOTAL
	, [RK_AVG_CHAT_MSG_RESP_TIME]: CT_GENERAL_TOTAL_TIME
	, [RK_AVG_CHAT_RESP_TIME]: CT_GENERAL_TOTAL_TIME
	, errands_responeded_according_to_sla_time_percentage: CT_GENERAL_TOTAL
	, errands_responeded_sla_time_total: CT_GENERAL_TOTAL
	, [RK_AVG_ERD_UNANSWRD_TIME]: CT_GENERAL_TOTAL_TIME
	, channel: CT_GENERAL_TOTAL
	, customAbsoluteTime: CT_GENERAL_TOTAL_TIME
	, [OC_ACTIVE_AGENTS]: CT_GENERAL_TOTAL_AGENT // TODO: remove this useless
	, [OC_LEADERBOARD]: CT_LEADERBOARD // TODO: remove this useless
	, [OC_ORGANISATION]: CT_ORG_BAR // TODO: remove this useless
	, overview_new_errands: CT_ORG_BAR
	, facebook_ratings_yes: CT_DOUGHNUT
	, facebook_ratings_no: CT_DOUGHNUT
	, active_agent: CT_GENERAL_TOTAL_AGENT
	, active_chat_agent: CT_GENERAL_TOTAL_AGENT
	, timestamp_lastseen: CT_GENERAL_TOTAL
	, lastseen_from_now: CT_GENERAL_TOTAL
	, collaboration_answered: CT_GENERAL_TOTAL
	, collaboration_unanswered: CT_GENERAL_TOTAL
	, number_of_contacts: CT_CONTACTSTOPLIST
	, collaboration_total: CT_GENERAL_TOTAL
	, sip_missed_calls: CT_GENERAL_TOTAL
	, sip_abandoned_calls: CT_GENERAL_TOTAL
};

export const LEADERBOARD_STR = I("Leaderboard")
	, ORG_STR = I("Organization")
	, CONTACTS_TOPLIST_STR = I("Number of Contacts")
	;
// charts layout
export const CL_OVERVIEW = 'ov'
	, CL_REPORT = 'rprt'
	;

// report page layout
export const RL_LEFT_PANE = 0
	, RL_RIGHT_PANE = 1
	, RL_BOTTOM_PANE = 2
	, RL_COMPARE_PANE = 3
	, RL_SUB_COMPARE_PANE = 4
	, RC_LIMITS = {
		[RL_LEFT_PANE]: 1
		, [RL_RIGHT_PANE]: 1
		, [RL_BOTTOM_PANE]: 1
	}
	;
// cornered position
export const C_BOTTOM_LEFT = "bottom-left"
	, C_BOTTOM_RIGHT = "bottom-right"
	, C_TOP_LEFT = "top-left"
	, C_TOP_RIGHT = "top-right"
	;
// report drilldown result type
export const RDDT_NORMAL = 1
	, RDDT_UNSUPPORTED = 2
	;
// report drilldown column data type
export const RDDCDT_UNKNOWN = 1
	, RDDCDT_NORMALIZED = 2
	, RDDCDT_AGENT = 3
	, RDDCDT_AREA = 4
	, RDDCDT_BOOL = 5
	, RDDCDT_CLOSE_TYPE = 6
	, RDDCDT_DATE_TIME = 7
	, RDDCDT_DURATION = 8
	, RDDCDT_ORGANIZATION = 9
	, RDDCDT_FALLBACK = 10
	, RDDCDT_FOLDER = 11
	, RDDCDT_SINCE_NOW = 12
	, RDDRDT_CHAT_RATING = 13
	;
export const TYPE_RDDCDT_UNKNOWN = {type: RDDCDT_UNKNOWN}
	, TYPE_RDDCDT_NORMALIZED = {type: RDDCDT_NORMALIZED}
	;
const oldErrandId = "old_errand_id"
	, typeRDDCDT_DATE_TIME = {type: RDDCDT_DATE_TIME}
	, typeRDDCDT_DURATION = {type: RDDCDT_DURATION}
	, typeRDDCDT_BOOL = {type: RDDCDT_BOOL}
	, typeRDDCDT_ERRAND_ID = {
		type: RDDCDT_FALLBACK
		, arguments: oldErrandId
		, usePivot: oldErrandId
	}
	, typeRDDRDT_CHAT_RATING = {type: RDDRDT_CHAT_RATING}

	;
// map backend drilldown data key to front-end data type identifier. (REQUIRE-BACKEND)
export const COL_AREA_ID = "area_id"
	, COL_ERRAND_ID = "errand_id"
	, COL_RESPONSE_TIME = "response_time"
	, COL_WAITING_TIME = "waiting_time"
	, COL_REVIEW_MESSAGE = "review_message"
	, COL_QUEUE_DURATION = "queue_duration"
	, COL_PROCESS_TIME = "process_time"
	, COL_TIME_SPENT = "time_spent"
	, COL_TS_LASTSEEN = "timestamp_lastseen"
	, COL_TS_WHEN = "timestamp_when"
	, MAP_COL_ID_TO_RDDCDT = {
		accepted: typeRDDCDT_BOOL
		, [COL_AREA_ID]: {type: RDDCDT_AREA, name: I("Area")}
		, close_type: {type: RDDCDT_CLOSE_TYPE}
		, [COL_ERRAND_ID]: typeRDDCDT_ERRAND_ID
		, [COL_QUEUE_DURATION]: {type: RDDCDT_SINCE_NOW}
		, [COL_RESPONSE_TIME]: typeRDDCDT_DURATION
		, [COL_WAITING_TIME]: typeRDDCDT_DURATION
		, [COL_PROCESS_TIME]: typeRDDCDT_DURATION
		, rejected_closed: typeRDDCDT_BOOL
		, rejected_unavailable: typeRDDCDT_BOOL
		, responded_within_sla: typeRDDCDT_BOOL
		, [COL_TIME_SPENT]: typeRDDCDT_DURATION
		, timestamp_arrive: typeRDDCDT_DATE_TIME
		, timestamp_close: typeRDDCDT_DATE_TIME
		, timestamp_create: typeRDDCDT_DATE_TIME // maybe we should use timestamp_created?
		, timestamp_created: typeRDDCDT_DATE_TIME
		, timestamp_end: typeRDDCDT_DATE_TIME
		, timestamp_received: typeRDDCDT_DATE_TIME
		, timestamp_lastupdate: typeRDDCDT_DATE_TIME
		, timestamp_open: typeRDDCDT_DATE_TIME
		, timestamp_sent: typeRDDCDT_DATE_TIME
		, timestamp_start: typeRDDCDT_DATE_TIME
		, agentrating: typeRDDRDT_CHAT_RATING
		, [COL_TS_WHEN]: typeRDDCDT_DATE_TIME
		, [COL_TS_LASTSEEN]: typeRDDCDT_DATE_TIME
		, user_id: {type: RDDCDT_AGENT, name: I("Agent")}
		, folder_id: {type: RDDCDT_FOLDER, name: I("Folder")}
		, waiting_time_working_time: typeRDDCDT_DURATION
	}
	;
// drilldown result data type
const DDRDT_ERRAND = "errand"
	, DDRDT_USER = "user"
	, DDRDT_ACTIVITY = "activity"
	, DDRDT_CHAT = "chat"
	, DDRDT_CHATMESSAGE = "chatmessage"
	, DDRDT_CHATSESSION = "chatsession"
	, DDRDT_ACTIVEAGENT = "activeagent"
	, DDRDT_FBRATING = "fbrating"
	, DDRDT_COLLABORATION = "collaboration"
	;
// TODO: parse from backend
export const MAP_DATA_TYPE_TO_AGG_DRILLDOWN = {
	[DDRDT_ERRAND]: COL_ERRAND_ID
	, [DDRDT_USER]: COL_PROCESS_TIME // COL_ERRAND_ID
	, [DDRDT_ACTIVITY]: COL_TIME_SPENT
	, [DDRDT_CHAT]: COL_TS_WHEN
	, [DDRDT_CHATMESSAGE]: COL_RESPONSE_TIME
	, [DDRDT_CHATSESSION]: COL_ERRAND_ID
	, [DDRDT_ACTIVEAGENT]: COL_TS_LASTSEEN
	, [DDRDT_FBRATING]: COL_REVIEW_MESSAGE
	, [DDRDT_COLLABORATION]: COL_RESPONSE_TIME
	, [DDRDT_COLLABORATION]: COL_WAITING_TIME
};
// dropdown group type.
export const DDG_MULTI_AREAS = 1
	, DDG_MULTI_AGENTS = 2
	, DDG_MULTI_CHANNELS = 3
	, DDG_MULTI_SIMPLE_TAGS = 4
	, DDG_MULTI_TAGS = 5
	, DDG_ADVANCE = 7
	, DDG_INTERVAL_DATE = 8
	, DDG_ERRAND_TYPE = 9
	, DDG_DATE_TYPE = 10
	, DDG_REL_DATETIME = 11
	, DDG_AGENT_TYPES = 12
	, DDG_GROUPS = 13
	, DDG_TIME_FORMAT = 14
	, DDG_TIME_SPAN_FORMAT = 15
	, DDG_TIME_ZONE = 16
	, DDG_SORT_DIRECTION = 17
	, DDG_SORT_ATTR = 18
	, DDG_MULTI_SERVICE_IDS = 19
	, DDG_DATE_FROM = 20
	, DDG_DATE_TO = 21
	, DDG_WIDGET_REPORT_TYPE = 22
	, DDG_WIDGET_REPORT_LIST = 23
	, DDG_WIDGET_CHART_TYPE = 24
	, DDG_EXISTING_OV_CHARTS = 25
	, DDG_AGGREGATE_TYPE = 26
	, DDG_MULTI_CONNECTEDAREAS = 27
	, DDG_COLLABORATORS = 28
	, DDG_DATE_COMPARE = 29
	;

// dropdown search filters
export const FILTER_SEARCH_SORT_ATTRIBUTES = [
	{id: 0, name: I("Date")}
	, {id: 1, name: I("ID")}
	, {id: 2, name: I("Subject")}
	, {id: 3, name: I("Area")}
	, {id: 4, name: I("Agent")}
	, {id: 5, name: I("From")}
	, {id: 6, name: I("Closed")}
];

export const FILTER_SEARCH_SORT_DIRECTION = [
	{id: 0, name: I("Ascending")}
	, {id: 1, name: I("Descending")}
];

// advance option type.
export const AOT_DATE_INTERVAL = ADV_SEARCH_DATE_INTERVAL
	, AOT_SHOW_INACTIVE = 2
	, AOT_ERRAND_TYPE = ADV_SEARCH_ERRAND_TYPE
	;
// errand openning states
export const ST_JUST_LOGIN = 0
	, ST_UNSELECT = 1
	, ST_ACQUIRING = 2
	, ST_ACQUIRED = 3
	, ST_ANSWERED = 4
	;
//filter label by
export const FILTER_SEARCH_ERRAND_EMPTY = 0
	, FILTER_SEARCH_ERRAND_MY = 1
	, FILTER_SEARCH_ERRAND_ALL = 2
	, FILTER_SEARCH_ERRAND_CLOSE = 3;

export const DROPDOWN_TEXT_LIMIT = 50;

export const COMMON_OFFSET_MODIFIER = {
	offset: {
		enabled: true,
		offset: '0, 10'
	}
}

// blue bubble notification type
export const NOTIFICATION_WITH_KEEP = 0 // stored into notification list
	, NOTIFICATION_WITHOUT_KEEP = 1; // do not store into notification list
export const WFP_MANUAL_IM = 1
export const UI_SHOW_New_IM = 'showNewIM'
export const CTX_MANUAL_IM = 'Manual IM'
export const NEW_IM_ID = 0;
export const TMR_IM_COUNTER = 30000;
export const MESSAGE_INVALID_AGENT = I("* You must provide a valid agent name.")
export const MESSAGE_INVALID_AGENT_GROUP = I("* You must provide a valid agent group.")

export const FILTER_ALL_MESSAGE 	= "allMessages"
	, FILTER_BY_SUMMARIZED_HISTORY 	= "bySumHistory"
	, FILTER_BY_AGENT 				= "byAgents"
	, FILTER_BY_CUSTOMER 			= "byCustomers"
	, FILTER_BY_COLLABORATION 		= "byCollaborations"
	, FILTER_BY_INTERNAL_MESSAGE 	= "byInternalMessages";

export const VIEW_REPLY_PANEL_ID_FORMAT={id: "id", name: "label"}
	, VIEW_REPLY_PANEL = {
		KnowledgeBase: 	{id: 1, label: features["cention-library"] ? I("Knowledge base/Assist") : I("Assist") },
		Recipient: 		{id: 2, label: I("Recipients")},
		Toolbar:		{id: 3, label: I("Toolbar")},
		Subject:		{id: 4, label: I("Subject")}
	};
// error during open errand
export const ERR_CHAT_ERRAND_OWNED_BY_OTHER = 1
	, ERR_INVALID_OPEN_REVIEW = 2
	;
export const REPLY_CHANNEL_TO_SERVICE_TYPE = {
	[RC_EMAIL]: Errand.SERVICE_EMAIL
	, [RC_CHAT]: Errand.SERVICE_CHAT
	, [RC_FACEBOOK]: Errand.SERVICE_FACEBOOK
	, [RC_TWITTER]: Errand.SERVICE_TWITTER
	, [RC_LINKEDIN]: Errand.SERVICE_LINKEDIN
	, [RC_SMS]: Errand.SERVICE_SMS
	, [RC_LINE]: Errand.SERVICE_LINE
	, [RC_VK]: Errand.SERVICE_VKONTAKTE
	, [RC_VOICE]: Errand.SERVICE_VOICE
	, [RC_INSTAGRAM]: Errand.SERVICE_INSTAGRAM
	, [RC_YOUTUBE]: Errand.SERVICE_YOUTUBE
	, [RC_GOOGLEPLAY]: Errand.SERVICE_GOOGLEPLAY
	, [RC_AVENTA]: Errand.SERVICE_AVENTA
	, [RC_SLACK]: Errand.SERVICE_SLACK
	, [RC_MSTEAMS]: Errand.SERVICE_MICROSOFT_TEAMS
	, [RC_GOOGLECHAT]: Errand.SERVICE_GOOGLECHAT
	, [RC_JIRA]: Errand.SERVICE_JIRA
	, [RC_TRUSTPILOT]: Errand.SERVICE_TRUSTPILOT
	, [RC_GOOGLEREVIEW]: Errand.SERVICE_GOOGLEREVIEW
};

// contact book type.
export const CB_ALL_CONTACT = 0
	, CB_EXPERT_IN_AREA = 1
	, CB_CONTACT_CARD = 2
	, CB_AGENTS = 3
	, CB_ADDRESS_BOOK = 4
	;

export const ADD_TO_CONTACT_CARD = "AddToContactCard";
export const OPT_WHATSAPP_CLOUD_PREMISE = "CloudOrPremise";

export const TXT_SELECT_COMPANY = I("Select Company");

export const TWITTERTWEETLIMIT = 280;

// Use For Collaboration Thread (v.type)
export const EXPAND_BEFORE = 1
	, EXPAND_AFTER = 0;

export const FWD_TO_AGENT_STR = I("Forward to agent");

export const INPUT_EMPTY_WARNING = _INPUT_EMPTY_WARNING;

export const EMAIL_ADDRESS_EXIST = I("Email already used!");
export const EMAIL_ADDRESS_CANNOT_USE = I("The email address is already used by another agent, please choose different address.");

export const FORM_SUBMIT_EMPTY_WARNING = I("One or more required field is empty. Please check and try again.")

export const INPUT_SPACE_WARNING = I('You can not have spaces in the field.')
export const DUPLICATE_SIP_STATUS_NAME = I('Name already exist.')
export const NOTIFY_SIP_INCOMING = I('Sip incoming call.')

export const INVALID_JSON_ERROR = I("Invalid JSON");

export const DUPLICATE_AGENT_EXTERNAL_ID = I("External ID has already exist.");

// KnowledgeBase Type
export const KBT_LIBRARY = 'library'
	, KBT_CATEGORY = 'category'
	, KBT_QUESTION = 'question'
	, KBT_SUGGESTION = 'suggestion'
	, KBT_RATING = 'rating'
	, KB_TYPE = {
		0 : [KBT_LIBRARY]
		, 1 : [KBT_CATEGORY]
		, 2 : [KBT_QUESTION]
		, 3 : [KBT_SUGGESTION]
		, 4 : [KBT_RATING]
	}
	, KB_ACTIVE_TAB = {
		[KBT_LIBRARY]: 0
		, [KBT_CATEGORY]: 1
		, [KBT_QUESTION]: 2
		, [KBT_SUGGESTION]: 3
		, [KBT_RATING]: 4
	};

export const MESSENGER_ICON_LOVE	=	'love'
			, MESSENGER_ICON_SMILE	=	'smile'
			, MESSENGER_ICON_ANGRY	=	'angry'
			, MESSENGER_ICON_SAD	=	'sad'
			, MESSENGER_ICON_WOW	=	'wow'
			, MESSENGER_ICON_LIKE	=	'like'
			, MESSENGER_ICON_DISLIKE	=	'dislike'
			;

export const TS_WEEKDAYS = 1
	, TS_MONTHS = 2
	, TS_DAY = 3
	, TS_HOURS = 4
	, TS_MINUTES = 5
	, TS_SECONDS = 6
	, TS_WEEKS = 7
	, TS_YEARS = 8
	, TS_FORMAT_TYPE = {
		[TS_WEEKDAYS]: 'Weekdays'
		, [TS_MONTHS]: 'Months'
		, [TS_DAY]: 'Day'
		, [TS_HOURS]: 'Hours'
		, [TS_MINUTES]: 'Minutes'
		, [TS_SECONDS]: 'Seconds'
		, [TS_WEEKS]: 'Weeks'
		, [TS_YEARS]: 'Years'
};

export const TF_HOURS = 3
	, TF_MINUTES = 2
	, TF_SECONDS = 1
	, TF_FORMAT_TYPE = {
		[TF_HOURS]: 'Hours'
		, [TF_MINUTES]: 'Minutes'
		, [TF_SECONDS]: 'Seconds'
};

// User Vote
export const USER_VOTE_UP = 1
	, USER_VOTE_DOWN = 2;

export const ACQUIRE_STATUS_OPEN = 'Open'
	, ACQUIRE_STATUS_CLOSE = 'Close'

// Breadcrumbs path identifier
export const PATH_SEARCH_RESULT = 1
	, PATH_SEARCH = 2
	, PATH_ERRANDS = 3
	, PATH_EXPORT = 4
	, PATH_MESSAGES = 5
	, PATH_ALL_ERRANDS = 6
	, PATH_CLOSED_ERRANDS = 7
	, PATH_AREAS = 8
	, PATH_COMMENTS = 9
	, PATH_KNOWLEDGE_BASE = 10
	, PATH_CUST_CONTACTS = 11
	, PATH_CUST_NOTES = 12
	, PATH_EE_CONTACTS = 13
	, PATH_TAGS = 14
	, PATH_SEARCH_ANSWER = 15
	;


export const PATH_BREADCRUMBS_TEXT = {
	[PATH_SEARCH_RESULT]: I("Search results")
	, [PATH_SEARCH]: I("Search")
	, [PATH_ERRANDS]: I("Errands")
	, [PATH_EXPORT]: I("Export")
	, [PATH_MESSAGES]: I("Messages")
	, [PATH_ALL_ERRANDS]: I("All Errands")
	, [PATH_CLOSED_ERRANDS]: I("Closed errands")
	, [PATH_AREAS]: I("Areas")
	, [PATH_COMMENTS]: I("Internal Comments")
	, [PATH_KNOWLEDGE_BASE]: I("Knowledge base")
	, [PATH_CUST_CONTACTS]: I("Customer Contacts")
	, [PATH_CUST_NOTES] : I("Customer notes")
	, [PATH_EE_CONTACTS]: I("External Expert Contacts")
	, [PATH_TAGS]: I("Tags")
	, [PATH_SEARCH_ANSWER]: I("Search Answers")
	//From context
	, [NEW_ERRANDS]: I("New Errands")
	, [MY_ERRANDS]: I("My Errands")
	, [CTX_REVIEW]: I("Review Errands")
	, [SEARCH_ERRANDS]: I("Search Errands")
	, [VIP_ERRANDS]: I("VIP errands")
	, [POSTPONED_ERRANDS]: I("Postpone errands")
}

const REVIEW_TYPE_TO = 1
	, REVIEW_TYPE_SUBJECT_TEXT = 2

export const REVIEW_TYPE_SLICE = {
	[REVIEW_TYPE_TO]: I("To")
	, [REVIEW_TYPE_SUBJECT_TEXT]: I("Subject or text")
}

export const REVIEW_KEYWORDS_OPTION = [
	{id: REVIEW_TYPE_TO, name: REVIEW_TYPE_SLICE[REVIEW_TYPE_TO]}
	, {id: REVIEW_TYPE_SUBJECT_TEXT, name: REVIEW_TYPE_SLICE[REVIEW_TYPE_SUBJECT_TEXT]}
];

const EMAIL_TYPE = 1
	, FB_TYPE = 2
	, IG_TYPE = 3
	, TWEET_TYPE = 4
	, LINK_TYPE = 5
	, LINE_TYPE = 6
	, GCHAT_TYPE = 7
	, GPLAY_TYPE = 8
	, GREVIEW_TYPE = 9
	, YOUTUBE_TYPE = 10

const EMAIL_PATH_V5 = "v5/admin/email"
	, FB_PATH_V5 = "v5/admin/facebook"
	, IG_PATH_V5 = "v5/admin/instagram"
	, TWEET_PATH_V5 = "v5/admin/twitter"
	, LINK_PATH_V5 = "v5/admin/linkedin"
	, LINE_PATH_V5 = "v5/admin/line"
	, GCHAT_PATH_V5 = "v5/admin/googlechat"
	, GPLAY_PATH_V5 = "v5/admin/googleplaystore"
	, GREVIEW_PATH_V5 = "v5/admin/googlereview"
	, YOUTUBE_PATH_V5 = "v5/admin/youtube"

const EMAIL_PATH = "admin#accounts/email"
	, FB_PATH = "admin#accounts/facebook"
	, IG_PATH = "admin#accounts/instagram"
	, TWEET_PATH = "admin#accounts/twitter"
	, LINK_PATH = "admin#accounts/linkedin"
	, LINE_PATH = "admin#accounts/line"
	, GCHAT_PATH = "admin#accounts/googlechat"
	, GPLAY_PATH = "admin#accounts/googleplaystore"
	, GREVIEW_PATH = "admin#accounts/googlereview"
	, YOUTUBE_PATH = "admin#accounts/youtube"

export const ACCOUNTS_PATH_SLICE_V5 = {
	[EMAIL_TYPE]: EMAIL_PATH_V5
	, [FB_TYPE]: FB_PATH_V5
	, [IG_TYPE]: IG_PATH_V5
	, [TWEET_TYPE]: TWEET_PATH_V5
	, [LINK_TYPE]: LINK_PATH_V5
	, [LINE_TYPE]: LINE_PATH_V5
	, [GCHAT_TYPE]: GCHAT_PATH_V5
	, [GPLAY_TYPE]: GPLAY_PATH_V5
	, [GREVIEW_TYPE]: GREVIEW_PATH_V5
	, [YOUTUBE_TYPE]: YOUTUBE_PATH_V5
}

export const ACCOUNTS_PATH_SLICE = {
	[EMAIL_TYPE]: EMAIL_PATH
	, [FB_TYPE]: FB_PATH
	, [IG_TYPE]: IG_PATH
	, [TWEET_TYPE]: TWEET_PATH
	, [LINK_TYPE]: LINK_PATH
	, [LINE_TYPE]: LINE_PATH
	, [GCHAT_TYPE]: GCHAT_PATH
	, [GPLAY_TYPE]: GPLAY_PATH
	, [GREVIEW_TYPE]: GREVIEW_PATH
	, [YOUTUBE_TYPE]: YOUTUBE_PATH
}

export const ACCOUNTS_VIEW_SLICE = {
	[EMAIL_TYPE]: M_ACC_EMAIL
	, [FB_TYPE]: M_ACC_FACEBOOK
	, [IG_TYPE]: M_ACC_INSTAGRAM
	, [TWEET_TYPE]: M_ACC_TWITTER
	, [LINK_TYPE]: M_ACC_LINKEDIN
	, [LINE_TYPE]: M_ACC_LINE
	, [GCHAT_TYPE]: M_ACC_GOOGLECHAT
	, [GPLAY_TYPE]: M_ACC_GOOGLEPLAY
	, [GREVIEW_TYPE]: M_ACC_GOOGLEREVIEW
	, [YOUTUBE_TYPE]: M_ACC_YOUTUBE
}

function getRTCConf() {
	let conf = { iceServers :
		[{urls: "stun:stun.l.google.com:19302"}
		,{urls: "stun:stun1.l.google.com:19302"}
		,{urls: "stun:stun2.l.google.com:19302"}
		,{urls: "stun:stun3.l.google.com:19302"}
		,{urls: 'stun:stun4.l.google.com:19302' }
	]
	, iceTransportPolicy: "all"
	, iceCandidatePoolSize: 0
	}

	if (navigator.userAgent.indexOf("Firefox") > 0) {
		//Mozilla only allow 1 iceServer
		conf = { "iceServers" : [ {urls: "stun:stun.l.google.com:19302"} ]
				, "iceTransportPolicy":"all","iceCandidatePoolSize":"0"
			};
	}
	return conf;
}

export const RTCCONFIG_TEMP = getRTCConf();

export const RINGER_AUDIO_SRC = process.env.CLOUDFRONT_URL + '/sounds/Ringing_Phone.mp3';

export const BEEP_AUDIO_SRC = process.env.CLOUDFRONT_URL + '/sounds/beep-02.mp3';

//routing rule - keywords
//id for "Type"
export const ROUTE_KEYWORDS_FROM = "From"
	, ROUTE_KEYWORDS_SUBJECT = "Subject"
	, ROUTE_KEYWORDS_SUBJECT_OR_TEXT = "Subject or text"
	, ROUTE_KEYWORDS_TO = "To"

export const ROUTE_KEYWORDS_OPTION = [
	{id: ROUTE_KEYWORDS_FROM, name: I("From")}
	, {id: ROUTE_KEYWORDS_SUBJECT, name: I("Subject")}
	, {id: ROUTE_KEYWORDS_SUBJECT_OR_TEXT, name: I("Subject or text")}
	, {id: ROUTE_KEYWORDS_TO, name: I("To")}
];

export const INTERACTIVE_MSG_OPTION = [
	{id: 0, name: I("List")}
	, {id: 1, name: I("Button")}
];

export const INTERACTIVE_LIST_OPTION = [
	{id: 0, name: I("List")}
];

export const INTERACTIVE_BUTTON_OPTION = [
	{id: 1, name: I("Button")}
];

export const INTERACTIVE_LIST = 'list', INTERACTIVE_BUTTON = 'button';
export const INTERACTIVE_LIST_TYPE = 0, INTERACTIVE_BUTTON_TYPE = 1;

export const INTERACTIVE_NUMBER_OF_OPTION = [
	{id: 1, name: "1"}
	, {id: 2, name: "2"}
	, {id: 3, name: "3"}
	, {id: 4, name: "4"}
	, {id: 5, name: "5"}
	, {id: 6, name: "6"}
	, {id: 7, name: "7"}
	, {id: 8, name: "8"}
	, {id: 9, name: "9"}
	, {id: 10, name: "10"}
];

export const QUICK_REPLY_MSG_TYPES = [
	{id: 0, name: I("Text")}
	, {id: 1, name: I("Phone")}
	, {id: 2, name: I("Email")}
];

export const QUICK_REPLY_MSG_OPTION = [
	{id: 0, name: I("Message")}
	, {id: 1, name: I("Template")}
];

export const QUICK_REPLY_MESSAGE_OPTION = [
	{id: 0, name: I("Message")}
];

export const QUICK_REPLY_TEMPLATE_OPTION = [
	{id: 1, name: I("Template")}
];
export const QUICK_REPLY_MESSAGE_DATA = 'msgData';
export const QUICK_REPLY_MESSAGE = 'message', QUICK_REPLY_TEMPLATE = 'template';
export const QUICK_REPLY_TYPE_MESSAGE = 0, QUICK_REPLY_TYPE_TEMPLATE = 1;
export const QUICK_REPLY_MESSAGE_TEXT = 0, QUICK_REPLY_MESSAGE_PHONE = 1, QUICK_REPLY_MESSAGE_EMAIL = 2;
export const QUICK_REPLY_MSG_TEXT_TYPE = [
	{id: 0, name: I("Text")}
];
export const QUICK_REPLY_MSG_PHONE_TYPE = [
	{id: 1, name: I("Phone")}
];
export const QUICK_REPLY_MSG_EMAIL_TYPE = [
	{id: 2, name: I("Email")}
];

export const USER_STATUS_CLASSNAME_OPTION = [
	{id: "available", name: I("Available")}
	, {id: "away", name: I("Away")}
	, {id: "offline", name: I("Offline")}
];

export const ERROR_MESSAGE = I("Oops something went wrong, please contact support.")

export const SENT = 0
export const CLOSED = 1

//Co-Browsing functionality
export const ACT_MOUSE_CURSOR = 0
	, ACT_KEYBOARD_INPUT = 1
	, ACT_SCROLL_PAGE = 2
;

export const BOTTOM_LEFT = "bottomLeft"
	, BOTTOM_RIGHT = "bottomRight"
	, CENTER_LEFT = "centerLeft"
	, CENTER_RIGHT = "centerRight"

export const WIDGET_POSITION_OPTION = [
	{id: BOTTOM_LEFT, name: I("Bottom Left")}
	, {id: BOTTOM_RIGHT, name: I("Bottom Right")}
	, {id: CENTER_LEFT, name: I("Center Left")}
	, {id: CENTER_RIGHT, name: I("Center Right")}
];

export const WIDGET_MODERN_POSITION_OPTION = [
	{id: BOTTOM_LEFT, name: I("Bottom Left")}
	, {id: BOTTOM_RIGHT, name: I("Bottom Right")}
];

export const WIDGET_SKIN_DEFAULT = "default"
	, WIDGET_SKIN_MODERN = "modern"
	, WIDGET_SKIN_FRICTIONLESS = "frictionless"

export const WIDGET_SKIN_OPTION = [
	{id: WIDGET_SKIN_DEFAULT, name: I("Default")}
	, {id: WIDGET_SKIN_MODERN, name: I("Modern")}
	// , {id: WIDGET_SKIN_FRICTIONLESS, name: I("Frictionless")}
	// uncomment the above once cflag.IsActive("2023-11-23.CEN-1888-new-chat-widget-skin") is confirmed & removed
];
export const FAQ_WIDGET_SKIN_DEFAULT = "default"
	, FAQ_WIDGET_SKIN_MODERN = "modern"
	, FAQ_WIDGET_SKIN_FRICTIONLESS = "frictionless"

export const FAQ_WIDGET_SKIN_OPTION = [
	{id: FAQ_WIDGET_SKIN_DEFAULT, name: I("Default")}
	, {id: FAQ_WIDGET_SKIN_MODERN, name: I("Modern")}
	// , {id: FAQ_WIDGET_SKIN_FRICTIONLESS, name: I("Frictionless")}
	// uncomment the above once cflag.IsActive("2024-06-14.CEN-1889-new-faq-widget-skin") is confirmed & removed
];

export const FAQ_WIDGET_POSITION_OPTION = [
	{id: BOTTOM_LEFT, name: I("Bottom Left")}
	, {id: BOTTOM_RIGHT, name: I("Bottom Right")}
];

export const START_TEMPLATE_DATA = "[FOLLOWUP:"
	, END_TEMPLATE_DATA   = ":FOLLOWUP]";

export const SHORTCUT_SEARCH_TERM = 1
	, SHORTCUT_ATTACH_ARCHIVE = 2
	, SHORTCUT_OPEN_TEMPLATES = 3
	, SHORTCUT_OPEN_KNOWLEDGEBASE = 4

export const SHORTCUT_SEARCH = [
	{id: SHORTCUT_SEARCH_TERM, name : I("/search [terms]"), desc : I("Search for matching words in errands")}
	// TODO
/* 	, {id: SHORTCUT_ATTACH_ARCHIVE, name : I("/attach [archive filename]"), desc : I("Attache file from archives")}
	, {id: SHORTCUT_OPEN_TEMPLATES, name : I("/templates"), desc : I("Open templates")}
	, {id: SHORTCUT_OPEN_KNOWLEDGEBASE, name : I("/knowledgebase"), desc : I("Open Knowledge base")}
 */];

 function getLogo(collapsed) {
	const CENTIONLOGO = cloudFrontURL + "/img/cention-full.svg";
	const CENTIONLOGO_MINI = cloudFrontURL + "/img/cention-mini.svg";
	const CENTIONLOGO_LIGHTER = cloudFrontURL + "/img/cention-full-lighter.svg";
	const CENTIONLOGO_MINI_LIGHTER = cloudFrontURL + "/img/cention-mini-lighter.svg";
	let logo = CENTIONLOGO;

	const darkModeAppearance = features["workflow.dark.mode"];

	switch (darkModeAppearance) {
        case (DARK_MODE_APPEARANCE[0].id): //no
			if(collapsed) {
				logo = CENTIONLOGO_MINI;
			} else {
				logo = CENTIONLOGO;
			}
          break;
        case (DARK_MODE_APPEARANCE[1].id): //yes
			if(collapsed) {
				logo = CENTIONLOGO_MINI_LIGHTER;
			} else {
				logo = CENTIONLOGO_LIGHTER;
			}
          break;
        case (DARK_MODE_APPEARANCE[2].id): //auto
          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				if(collapsed) {
					logo = CENTIONLOGO_MINI_LIGHTER;
				} else {
					logo = CENTIONLOGO_LIGHTER;
				}
          } else {
            // darkTheme = false;
				if(collapsed) {
					logo = CENTIONLOGO_MINI;
				} else {
					logo = CENTIONLOGO;
				}
          }
          break;
        default:
			if(collapsed) {
				logo = CENTIONLOGO_MINI;
			} else {
				logo = CENTIONLOGO;
			}
          break;
      }
	return logo;
 }

export const CENTION_LOGO = getLogo(false);
export const CENTION_LOGO_MINI = getLogo(true);

export const USE_XHR_CREATE_AGENT_ACCESS_KEY = true;


//social channels icon according to view

//NOTE. match with back-end menu id.
export const SOCIAL_CHANNEL_VIEW_ICONS = {
	[M_ACC_EMAIL]: "icon-v5-email",
	[M_ACC_LINE]: "icon-v5-line",
	[M_ACC_FACEBOOK]: "icon-v5-facebook",
	[M_ACC_INSTAGRAM]: "icon-v5-instagram",
	[M_ACC_LINKEDIN]: "icon-v5-linkedin",
	[M_ACC_JIRA]: "icon-jira",
	[M_ACC_HUBSPOT]: "icon-hubspot",
	[M_ACC_MSTEAMS]: "icon-ms-teams",
	[M_ACC_GOOGLEREVIEW]: "icon-star",
	[M_ACC_GOOGLECHAT]: "icon-google-chat",
	[M_ACC_GOOGLEPLAY]: "icon-v5-play-store",
	[M_ACC_TRUSTPILOT]: "icon-trustpilot",
	[M_ACC_TELEGRAM]: "icon-telegram",
	[M_ACC_WHATSAPP]: "icon-v5-whatsapp",
	[M_ACC_SMPP]: "icon-v5-email",
	[M_ACC_SMTP]: "icon-mail-arrow-left",
	[M_ACC_TWILIO]: "icon-twilio",
	[M_ACC_VIBER]: "icon-viber",
	[M_ACC_SLACK]: "icon-v5-slack",
	[M_ACC_YOUTUBE]: "icon-youtube",
	[M_ACC_TWITTER]: "icon-x-twitter"
};

export const AUTHSERVERURL = "https://cloud.cention.com/auth/";
export const WEBHOOKAUTHURL = "https://webhook.cention.com/fbd/";

//acount form type
export const FORM_EMPTY = 0
export const FORM_EDIT = 1
export const FORM_CREATEGROUP = 2
export const FORM_MANAGEGROUP = 3
export const FORM_LISTGROUP = 4
export const FORM_UPLOAD = 5

//account endpoints based on view
export const ACCOUNT_ENDPOINTS = {
	[M_ACCOUNTS]: AdminAccountEmail,
	[M_ACC_EMAIL]: AdminAccountEmail,
	[M_ACC_FACEBOOK]: AdminAccountFacebook,
	[M_ACC_INSTAGRAM]: AdminAccountInstagram,
	[M_ACC_TWITTER]: AdminAccountTwitter,
	[M_ACC_LINKEDIN]: AdminAccountLinkedIn,
	[M_ACC_JIRA]: AdminAccountJira,
	[M_ACC_HUBSPOT]: AdminAccountHubSpot,
	[M_ACC_MSTEAMS]: AdminAccountMSTeams,
	[M_ACC_GOOGLEREVIEW]: AdminAccountGoogleReview,
	[M_ACC_GOOGLECHAT]: AdminAccountGoogleChat,
	[M_ACC_GOOGLEPLAY]: AdminAccountGooglePlay,
	[M_ACC_TRUSTPILOT]: AdminAccountTrustpilot,
	[M_ACC_TELEGRAM]: AdminAccountTelegram,
	[M_ACC_WHATSAPP]: AdminAccountWhatsApp,
	[M_ACC_TWILIO]: AdminAccountTwilio,
	[M_ACC_VIBER]: AdminAccountViber,
	[M_ACC_SLACK]: AdminAccountSlack,
	[M_ACC_YOUTUBE]: AdminAccountYoutube,
	[M_ACC_SMPP]: AdminAccountSMPP,
	[M_ACC_LINE]: AdminAccountLine
}

export const IVR_CONNECT_OPTIONS = [
	{ id: 1, name: "Phone menu", actionType: "dtmf" },
	{ id: 2, name: "Agent", actionType: "directforward" },
	{ id: 3, name: "Callback with a new number", actionType: "newcallbackerrand" }, //create a callback errand after prompting a new number
	{ id: 4, name: "Callback", actionType: "promptandnewErrandType"}, //create a callback errand
	{ id: 5, name: "Call queue", actionType: "returnqueue" },
	{ id: 6, name: "End call" }
]

//map action type with ID
export const IVR_CONNECT_MAP = {
	'dtmf': 1
	, 'directforward': 2
	, 'newcallbackerrand':3
	, 'promptandnewErrandType': 4
	, 'returnqueue': 5
	, 'end': 6
};

export const IVR_CONNECT_ID_MAP = {
	1: 'dtmf'
	, 2: 'directforward'
	, 3: 'newcallbackerrand'
	, 4: 'promptandnewErrandType'
	, 5: 'returnqueue'
	, 6: 'end'
};

export const IVR_CONNECT_ICON_MAP = {
	'dtmf': 'icon-keypad'
	, 'directforward': 'icon-user-outline'
	, 'newcallbackerrand': 'icon-user-outline'
	, 'promptandnewErrandType': 'icon-user-outline'
	, 'returnqueue': 'icon-user-outline'
	, 'end': ''
};

//Agent's favourite
export const AGENT_FAV_FORWARD_TO_AREA = "favouriteForwardToArea"; //IMPORTANT: this saves field to backend. Cant just rename this
export const AGENT_FAV_AI_TRANSLATE_LANGUAGES = "favouriteAITranslateLanguages";
export const ICON_STAR = "icon-star";
export const ICON_STAR_OUTLINE = "icon-star-outline";

// PROACTIVE CHAT DEFAULTS
export const CHAT_PROACTIVE_DEFAULTS = {
	enable: false,
	delayTime: "30",
	timeAfterDecline: "45",
	repeatedContact: "2",
	timeout: "20",
	immediateStart: false,
	popupType: 1,
	imageURL: "",
}

// Announcement tab
export const ANM_TAB_NOTIFICATION = "notifcation"
	, ANM_TAB_ANNOUNCEMENT = "announcement"
