import {
	ICON_V5_EMAIL
	, RC_EMAIL
	, RC_CHAT
	, RC_FACEBOOK
	, RC_TWITTER
	, RC_LINKEDIN
	, RC_SMS
	, RC_LINE
	, RC_VK
	, RC_VOICE
	, RC_INSTAGRAM
	, RC_YOUTUBE
	, RC_GOOGLEPLAY
	, RC_AVENTA
	, RC_SLACK
	, RC_MSTEAMS
	, RC_GOOGLECHAT
	, RC_JIRA
	, RC_TRUSTPILOT
	, RC_GOOGLEREVIEW
} from "../common/v5/constants";

export function getInitialData() {
	let path = process.env.PUBLIC_URL;
	let searchQuery = "";
        if(window.location.origin !== ""){
            path = window.location.origin;
        }
	if(window.location.search !== ""){
		searchQuery = window.location.search;
		if(!searchQuery.includes('?')){
			searchQuery = "?" + searchQuery
		}
	}
	const url = path + process.env.ORIGIN_PATH +"ui/api/workflow" + searchQuery;
	return fetch(url, {
			credentials: "include"
		})
		.then((res) => {
			if (!res.ok) {
				throw new Error("HTTP status:" + res.status);
			}
			return res.json();
		})
		.then(json => json)
		.catch(error => error)
}

export function getFeedbackData(id, ws) {
	const wss = `/s/${ws}`
	let path = process.env.PUBLIC_URL;

        if(window.location.origin !== ""){
            path = window.location.origin;
        }

	const url = path + wss + process.env.ORIGIN_PATH +"/publicPage/fdb/" + id;
	return fetch(url, {mode: 'no-cors'})
		.then((res) => res.json())
		.then(json => json);
}

export async function saveError(data) {
	let path = process.env.PUBLIC_URL;

        if(window.location.origin !== ""){
            path = window.location.origin;
        }
	const url = path + process.env.ORIGIN_PATH +"jserror";
	return await fetch(url, {
		method: "POST",
		body: JSON.stringify(data)
	})
	.then((res) => res.json())
	.then(json => json);
}

export const setGlobals = (res) => {
	space = res.space;
	workspace = res.space;
	constants = res.constants;
	window.constants = constants;
	currentInterface = res.interfaceLanguage;
	currentSpelling = res.spellLanguage;
	newErrandCount = res.newErrandCount;
	myErrandCount = res.myErrandCount;
	menu = res.menu;
	orgArea = res.orgArea;
	orgCompleteListsArea = res.orgCompleteListsArea;
	features = res.features;
	initialChatData = res.initialChatData;
	Workflow = {
		Errand: res.wfErrandService,
		Mail: res.wfMailService,
		Action: res.wfActionType,
		ErrandStates: res.wfErrandStates,
		CallbackEvents: res.wfCallbackType
	};
	ServicesName = res.servicesName;
	autoLogout = res.autoLogout;
	hideMenuTicker = res.hideMenuTicker;
	handleType = res.handleType;
	errandViewOnly = false;
	externalqueue = res.externalqueue;
	activeUserId = res.activeUserId;
	callService = res.callService;
	_version = res.version;
	cflag = res.cflag;
	cflag.IsActive = function( flag ){
		var c = res.cflag
		, chunks
		, i
		, v
		;
		console.log('flag >>>>>>= ', flag);
		if ( !flag ) {
			return false;
		}
		chunks = flag.split(".");
		for (i=0; i<chunks.length; i++) {
			v = c[chunks[i]];
			switch (typeof v) {
			case 'object':
				c = v;
				break;
			case 'boolean':
				return v;
			default:
				return false;
			}
		}
		return false;
	}
	DEFAULT_ERRAND_TYPE_ICONS = {
		[Workflow.Errand.SERVICE_EMAIL]: ICON_V5_EMAIL
		, [Workflow.Errand.SERVICE_MANUAL]: ICON_V5_EMAIL
		, [Workflow.Errand.SERVICE_FACEBOOK]: "icon-v5-facebook"
		, [Workflow.Errand.SERVICE_MANUAL_FACEBOOK]: "icon-v5-facebook"
		, [Workflow.Errand.SERVICE_TWITTER]: "icon-x-twitter"
		, [Workflow.Errand.SERVICE_MANUAL_TWITTER]: "icon-x-twitter"
		, [Workflow.Errand.SERVICE_LINKEDIN]: "icon-v5-linkedin"
		, [Workflow.Errand.SERVICE_MANUAL_LINKEDIN]: "icon-v5-linkedin"
		, [Workflow.Errand.SERVICE_INSTAGRAM]: "icon-v5-instagram"
		, [Workflow.Errand.SERVICE_MANUAL_INSTAGRAM]: "icon-v5-instagram"
		, [Workflow.Errand.SERVICE_LINE]: "icon-v5-line"
		, [Workflow.Errand.SERVICE_MANUAL_LINE]: "icon-v5-line"
		, [Workflow.Errand.SERVICE_VKONTAKTE]: "icon-v5-vk"
		, [Workflow.Errand.SERVICE_MANUAL_VKONTAKTE]: "icon-v5-vk"
		, [Workflow.Errand.SERVICE_CHAT]: "icon-v5-chat"
		, [Workflow.Errand.SERVICE_SMS]: "icon-v5-sms"
		, [Workflow.Errand.SERVICE_MANUAL_SMS]: "icon-v5-sms"
		, [Workflow.Errand.SERVICE_VOICE]: "icon-v5-phone"
		, [Workflow.Errand.SERVICE_MANUAL_VOICE]: "icon-v5-phone"
		, [Workflow.Errand.SERVICE_FORM]: "icon-webform"
		, [Workflow.Errand.SERVICE_YOUTUBE]: "icon-v5-youtube"
		, [Workflow.Errand.SERVICE_MANUAL_YOUTUBE]: "icon-v5-youtube"
		, [Workflow.Errand.SERVICE_WHATSAPP]: "icon-v5-whatsapp"
		, [Workflow.Errand.SERVICE_GOOGLEPLAY]: "icon-v5-play-store"
		, [Workflow.Errand.SERVICE_CHATBOT]: "icon-chatbot"
		, [Workflow.Errand.SERVICE_TELEGRAM]: "icon-telegram"
		, [Workflow.Errand.SERVICE_TRUSTPILOT]: "icon-trustpilot"
		, [Workflow.Errand.SERVICE_VIBER]: "icon-viber"
		, [Workflow.Errand.SERVICE_GOOGLEREVIEW]: "fas fa-business-time"
		, [Workflow.Errand.SERVICE_SLACK]: "icon-v5-slack"
		, [Workflow.Errand.SERVICE_MICROSOFT_TEAMS]: "icon-ms-teams"
	};
	REPLY_CHANNEL_TO_SERVICE_TYPE = {
		[RC_EMAIL]: Workflow.Errand.SERVICE_EMAIL
		, [RC_CHAT]: Workflow.Errand.SERVICE_CHAT
		, [RC_FACEBOOK]: Workflow.Errand.SERVICE_FACEBOOK
		, [RC_TWITTER]: Workflow.Errand.SERVICE_TWITTER
		, [RC_LINKEDIN]: Workflow.Errand.SERVICE_LINKEDIN
		, [RC_SMS]: Workflow.Errand.SERVICE_SMS
		, [RC_LINE]: Workflow.Errand.SERVICE_LINE
		, [RC_VK]: Workflow.Errand.SERVICE_VKONTAKTE
		, [RC_VOICE]: Workflow.Errand.SERVICE_VOICE
		, [RC_INSTAGRAM]: Workflow.Errand.SERVICE_INSTAGRAM
		, [RC_YOUTUBE]: Workflow.Errand.SERVICE_YOUTUBE
		, [RC_GOOGLEPLAY]: Workflow.Errand.SERVICE_GOOGLEPLAY
		, [RC_AVENTA]: Workflow.Errand.SERVICE_AVENTA
		, [RC_SLACK]: Workflow.Errand.SERVICE_SLACK
		, [RC_MSTEAMS]: Workflow.Errand.SERVICE_MICROSOFT_TEAMS
		, [RC_GOOGLECHAT]: Workflow.Errand.SERVICE_GOOGLECHAT
		, [RC_JIRA]: Workflow.Errand.SERVICE_JIRA
		, [RC_TRUSTPILOT]: Workflow.Errand.SERVICE_TRUSTPILOT
		, [RC_GOOGLEREVIEW]: Workflow.Errand.SERVICE_GOOGLEREVIEW
	}
	c3oauth = res.oauth;
	isUserAgent = res.isUserAgent;
	maxTagLevel = res.maxTagLevel;
}

export let translationURL = process.env.CLOUDFRONT_URL + "/strings/Generated/Cention.translation.{interfaceLanguage}.js?v={version}";
